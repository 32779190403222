exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes rg9Th{to{-webkit-transform:rotate(1turn)}}@keyframes rg9Th{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}.zwKWP{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.iaf-v{padding:20px 50px 40px}.PE0D3{color:#373737;color:var(--black,#373737);font-family:Open Sans;font-size:16px;font-style:normal;font-weight:400;line-height:120%;letter-spacing:.08px}.sVY0e{margin-top:20px}._2owl5{background:#ebebeb;display:-ms-flexbox;display:flex;height:111px;-ms-flex-pack:end;justify-content:end;left:0;top:273px;width:100%;border-bottom-left-radius:15px;border-bottom-right-radius:15px;gap:30px}._3FdOb{margin-top:auto;margin-bottom:auto}._23SQX{margin-right:30px}", ""]);

// exports
exports.locals = {
	"modal-container": "zwKWP",
	"modalBody": "iaf-v",
	"paragraph": "PE0D3",
	"formFieldContainer": "sVY0e",
	"buttonWrapper": "_2owl5",
	"buttonItem": "_3FdOb",
	"buttonSave": "_23SQX",
	"spin": "rg9Th"
};