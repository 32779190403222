import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './batchDeleteCustomBrouchureSection.scss';
import Modal from 'react-responsive-modal';
import sModal from 'react-responsive-modal/styles.css';
import { WarningIcons } from '../../../../../styledComponents/styles/IconCustom.styled';
import CloseIcon from '../../../../travelerProfile/components/icons/CloseIcon';
import { ThemeProvider } from 'styled-components';
import { themeViaForms } from '../../../../../styledComponents/settings/theme';
import toastMessageStyles from '../../../../../sites/viaGlobal/components/toastMessage/toastMessage.scss';
import { toast } from 'react-toastify';
import { AccentButtonMedium, ButtonMedium } from '../../../../../styledComponents/styles/Buttons.styled';
import { AccentButtonSpinner } from '../../../../../styledComponents/styles/spinner.styled';
import { 
        batchDeleteCustomBrochureSection, 
        deleteCustomBrochureSection,
        batchDeleteCustomBrochureSectionClear,
        deleteCustomBrochureSectionClear
      } from '../../../../../actions/programModifyActions';
import { getProgram } from '../../../../../actions/programBrochure';  

export const enhance = compose(withStyles(s, sModal));

function BatchDeleteCustomBrouchureSection(props) {
  const dispatch = useDispatch();

  const batchDeleteProgramBrochureSection = useSelector(state=>state.programModify.batchDeleteProgramBrochureSection);
  const deleteProgramBrochureSection = useSelector(state=>state.programModify.deleteProgramBrochureSection);

  const [isSaving, setIsSaving] = useState(false);
  
  useEffect(()=>{
    if(props.isBatchEdit) {
      setIsSaving(batchDeleteProgramBrochureSection?.loading ? true : false);
      if(batchDeleteProgramBrochureSection?.error) {
        toast('An error occurred while deleting the brochure section.', {
          type: 'error',
          className: toastMessageStyles['failureContainer'],
          toastClassName: toastMessageStyles['wrapper'],
          bodyClassName: toastMessageStyles['failureBody'],
          progressClassName: toastMessageStyles['progress-bar'],
        });
      }    

      if(batchDeleteProgramBrochureSection?.success && !batchDeleteProgramBrochureSection.loading) {
        toast('Brochure section deleted', {
          type: 'success',
          className: toastMessageStyles['successContainer'],
          toastClassName: toastMessageStyles['wrapper'],
          bodyClassName: toastMessageStyles['successBody'],
          progressClassName: toastMessageStyles['progress-bar'],
        }); 
        props.onClose(false);
      }
    }
  },[batchDeleteProgramBrochureSection]);


  useEffect(()=>{
    if(!props.isBatchEdit) {
      setIsSaving(deleteProgramBrochureSection?.loading ? true : false);
      if(deleteProgramBrochureSection?.error) {
        toast('An error occurred while deleting the brochure section.', {
          type: 'error',
          className: toastMessageStyles['failureContainer'],
          toastClassName: toastMessageStyles['wrapper'],
          bodyClassName: toastMessageStyles['failureBody'],
          progressClassName: toastMessageStyles['progress-bar'],
        });
      }    

      if(deleteProgramBrochureSection?.success && !deleteProgramBrochureSection.loading) {
        dispatch(getProgram(props.selectedPrograms, 'client'));
        toast('Brochure section deleted', {
          type: 'success',
          className: toastMessageStyles['successContainer'],
          toastClassName: toastMessageStyles['wrapper'],
          bodyClassName: toastMessageStyles['successBody'],
          progressClassName: toastMessageStyles['progress-bar'],
        }); 
        props.onClose(false);
      }
    }
  },[deleteProgramBrochureSection]);


  const deleteBrochureSection = () => {
    if(props.isBatchEdit) {
      const reqParams = {
        program_ids: props.selectedPrograms
      }
      dispatch(batchDeleteCustomBrochureSection(reqParams));
      setTimeout(()=>{
        dispatch(batchDeleteCustomBrochureSectionClear());
      },2000)
    } else {
      dispatch(deleteCustomBrochureSection(props.sectionID));
      setTimeout(()=>{
        dispatch(deleteCustomBrochureSectionClear());
      },2000)
    }
  }

  return (
    <ThemeProvider theme={themeViaForms}>
      <Modal
      blockScroll={false}
      open={props.show}
      onClose={() => props.onClose(false)}
      center
      closeOnEsc
      closeOnOverlayClick
      showCloseIcon={false}
      styles={{
        modal: {
          padding: '0px',
          borderRadius: '5px',
          width: '550px',
        },
      }}
    >
      <div className={s.header}>
        <h2 className={s.heading} >Warning</h2>
        <button className={s.closeButton}  onClick={()=>props.onClose(false)}>
          <CloseIcon fillColor={`#9FABAE`} />
        </button>
      </div>
      <div className={s.modalContainer}  >
        <div className={s.content} >
          <div className={s.iconWrapper} >
            <WarningIcons size='100px' />
          </div>
          <div className={s.paragraphContainer} >
            <div className={s.paragraphWarning} >
              You are about to delete {props.selectedPrograms.length} brochure section(s)
            </div>
            <div className={`${s.paragraphWarning}`} style={{marginTop:"15px"}}>This action cannot be undone and it will be removed from the program brochure.</div>
            <div className={s.paragraphWarning} style={{marginTop:"15px", fontWeight:"700"}}>
              Deleted data cannot be recovered
            </div>
          </div>
        </div>
        <div className={s.buttonWrapper}>
          <div className={s.buttonItem}>
            <ButtonMedium aria-label="Cancel" disabled={isSaving} onClick={() => props.onClose(false)}>
              Cancel
            </ButtonMedium>
          </div>
          <div className={s.buttonItem}>
            <AccentButtonMedium
              aria-label={`Continue`}
              disabled={isSaving}
              onClick={deleteBrochureSection}
              saving={isSaving}
            >
              {isSaving ? <AccentButtonSpinner displayText='Loading...' /> : ' Continue '}
            </AccentButtonMedium>
          </div>
        </div>
      </div>
    </Modal>
    </ThemeProvider>  
  );
}

BatchDeleteCustomBrouchureSection.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

export default enhance(BatchDeleteCustomBrouchureSection);
