import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import moment from 'moment-timezone';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import Button from '../../../../components/library/button';
import Paragraph from '../../../../components/library/paragraph';
import InputSelect from '../../../../components/library/inputSelect';
import sBatchAddTermNameAuth from './batchAddTermNameAuth.scss';
import { getBatchAuthTerms } from '../../../../../actions/reportsActions';
import OneClickModal from '../oneClickModal';

export const enhance = compose(withStyles(sBatchAddTermNameAuth));

function BatchAddTermNameAuth(props) {
  const dispatch = useDispatch();
  const [showBatchAddTermNameAuth, setShowBatchAddTermNameAuth] = useState(false);
  const [submitValues, setSubmitValues] = useState();
  const [suitcaseName, setSuitcaseName] = useState('');
  const userProfile = useSelector(state => state.profile);
  const addTerm = useSelector(state => state.reports.authTerms);
  const authAddTerm = useSelector(state => state.termNames.termNamesAuth);
  const [options, setOptions] = useState([]);
  const customAliases = useSelector(state => state.profile.customAliases);

  const [defaultValues, setDefaultValues] = useState(() => {
    let values = {
      name: '',
    };
    return values;
  });

  const resetForm = () => {
    setDefaultValues(() => {
      let values = {
        name: '',
      };
      return values;
    });
  };

  let termCustomStyles = {
    multiValue: provided => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided,
    }),
    container: (provided, state) => ({
      ...provided,
    }),
    placeholder: provided => ({
      ...provided,
      color: '#373737',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#373737',
    }),
    control: provided => ({
      ...provided,
      borderColor: '#ebebeb',
      width: '550px',
      minHeight: '44px',
    }),
  };

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      dispatch(getBatchAuthTerms());
    }
    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(
    () => {
      if (props.show) {
        dispatch(getBatchAuthTerms());
      }
    },
    [props],
  );

  useEffect(
    () => {
      const buildOptions = addTerm?.data?.map(term => {
        return {
          value: term.id,
          label: term.attributes.term_name.name,
          application_deadline: moment(term.attributes.application_deadline).isValid()
            ? moment.tz(term.attributes.application_deadline, userProfile.orgTimezone).format('MMMM D[,] YYYY h:mm a z')
            : 'None',
          application_template_name: term.attributes.application_template_name,
          suitcase_form_groupings: term.attributes.suitcase_form_groupings,
        };
      });

      setOptions(buildOptions);
    },
    [addTerm],
  );

  return (
    <>
      <ViaModal
        showing={props.show}
        onClose={() => {
          resetForm();
          props.onClose(false);
        }}
        headerText="Assign Suitcase"
        width="700px"
        upper={false}
      >
        <div className={sBatchAddTermNameAuth['batch-add-term-name-auth']}>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={defaultValues}
            validate={values => {
              const errors = {};

              if (!values.name) {
                errors.name = 'Term Name is required';
              }
              return errors;
            }}
            onSubmit={values => {
              const submitValues = {
                suitcase_id: parseInt(values.name.value),
                program_range_ids: props.programIds,
                source: 'authorized',
              };

              setSuitcaseName(values.name.label);
              setSubmitValues(submitValues);
              props.onClose(false);
              setShowBatchAddTermNameAuth(true);
            }}
          >
            {({ values, handleSubmit, errors }) => (
              <Form>
                <div className={sBatchAddTermNameAuth.section}>
                  <div className={sBatchAddTermNameAuth.row}>
                    <div className={sBatchAddTermNameAuth.instructions}>
                      <Paragraph size="normal">
                        Select a suitcase from the drop down to assign it to selected authorized terms.
                      </Paragraph>
                    </div>
                  </div>
                </div>{' '}
                <div className={sBatchAddTermNameAuth.section}>
                  <div className={sBatchAddTermNameAuth.row}>
                    <div className={sBatchAddTermNameAuth.item}>
                      <Field name="name">
                        {({ form }) => (
                          <InputSelect
                            id="name"
                            name="name"
                            aria-label="Term Name"
                            errorMessage={errors.name}
                            helpText=""
                            styles={termCustomStyles}
                            value={values.name.value}
                            options={options}
                            placeholder={values.name ? values.name.label : 'Select Suitcase'}
                            onChange={e => {
                              form.setFieldValue('name', e);
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className={sBatchAddTermNameAuth.section}>
                  {!!addTerm.data && values.name.value ? (
                    <div className={sBatchAddTermNameAuth.informationRow} key={values.name.value}>
                      <div className={sBatchAddTermNameAuth.suitCaseInformation}>
                        <Paragraph bold size="normal">
                          Suitcase: {values.name.label}
                        </Paragraph>
                        <Paragraph size="normal">
                          Application Template: {values?.name?.application_template_name}
                        </Paragraph>
                        <Paragraph size="normal">Deadline: {values?.name?.application_deadline}</Paragraph>

                        {values?.name?.suitcase_form_groupings?.map(form => (
                          <div className={sBatchAddTermNameAuth.formGroupContainer} key={form?.id}>
                            <Paragraph size="normal">
                              Form Group:{' '}
                              {form?.suitcase_form_grouping_templates?.map(form => form?.form_template_name).join(', ')}
                            </Paragraph>
                            <Paragraph size="normal">
                              Deadline:{' '}
                              {moment(form?.deadline).isValid()
                                ? moment(form?.deadline).format('MMM DD, YYYY')
                                : 'None'}
                            </Paragraph>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className={sBatchAddTermNameAuth.footer}>
                  <div className={sBatchAddTermNameAuth['button-content']}>
                    <Button
                      display="secondary"
                      kind="solid"
                      size="medium"
                      ariaLabel="Cancel Button"
                      onClick={() => {
                        props.onClose(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={sBatchAddTermNameAuth['button-content']}>
                    <Button
                      display={!values.name ? 'darkGrayDisabled' : 'primary'}
                      kind="solid"
                      size="medium"
                      type="submit"
                      ariaLabel="Add Term Button"
                      disabled={!values.name ? true : false}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ViaModal>
      <OneClickModal
        show={showBatchAddTermNameAuth}
        onClose={() => {
          resetForm();
          setShowBatchAddTermNameAuth(false);
          props.setSelectedPrograms();
        }}
        paragraphOne={
          <>
            You are about to assign the {suitcaseName} suitcase to <b>{props.programIds.length}</b> authorized{' '}
            {props.programIds.length === 1 ? `term` : `terms`}.<br />
            Adding this suitcase will enable {customAliases.alias_travelers.toLowerCase()} to start the application
            process.
          </>
        }
        paragraphTwo={'Are you sure you want to continue?'}
        paragraphTwoDemiBold
        cancelMessage={'Cancel'}
        confirmActionMessage={'Assign Suitcase'}
        headline={'Confirm Suitcase Assignment'}
        source="batch_add_term_name_auth"
        selectedPrograms={props.programIds}
        actionState={authAddTerm}
        addTermNameAuthSuccessMessage={`Suitcase assigned to ${props.programIds.length} ${
          props.programIds.length === 1 ? `term` : `terms`
        }`}
        centered
        submitValues={submitValues}
        width="700px"
      />
    </>
  );
}

BatchAddTermNameAuth.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

export default enhance(BatchAddTermNameAuth);
