import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { Cookies } from 'react-cookie';
import { pollUserInfo } from '../actions/authActions';

function useSession() {
  // const [counter, setCounter] = useState(0);
  const [showSessionExpire, setShowSessionExpire] = useState(false);
  const [countDownSeconds, setCountDownSeconds] = useState(60);
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('pooling started');
    
    setTimeout(() => {
        checkSession();
    }, 5000);

    sessionPooling();
  }, []);

  // useEffect(() => {
  //     console.log("counter ", counter);
  //     if(profile?.orgTimezone && tokenExpiresAt){
  //         console.log("token expires at ", tokenExpiresAt, " and client timezone ", profile?.orgTimezone);
  //         // setShowSessionExpire(true);
  //     }
  // }, [counter]);

  const handleSessionExpireModal = (tokenExpiration, timezone) => {
    const expirationTime = moment(tokenExpiration).tz(timezone);
    const currentTime = moment.tz(timezone);

    // Get the difference in minutes
    // const differenceInMinutes = expirationTime.diff(currentTime, 'minutes');
    // console.log("expiration time is ", expirationTime.format("YYYY-MM-DD HH:mm"), " and currentTime is ", currentTime.format("YYYY-MM-DD HH:mm"));
    // console.log("difference in minutes ", differenceInMinutes);

    // if(differenceInMinutes === 2){
    //     console.log("poll token");
    //     dispatch(pollUserInfo());
    // }

    // Calculate the difference in seconds
    const differenceInSeconds = expirationTime.diff(currentTime, 'seconds');

    // Check if the difference is greater than 0 and less than 60 seconds
    if (differenceInSeconds > 0 && differenceInSeconds < 60) {
      console.log('The difference is greater than 0 and less than 60 seconds.');
      setShowSessionExpire(true);
      setCountDownSeconds(differenceInSeconds);
      // Add your logic here
    }

    // Check if the difference is 0 or less than 0
    if (differenceInSeconds <= 0) {
      console.log('The difference is 0 or less than 0.');
      localStorage.setItem('is_session_expired', '1');
      window.location.assign(`/clear_cookies`);
      // Add your logic here
    }

    if (differenceInSeconds > 60) {
      setShowSessionExpire(false);
      setCountDownSeconds(60);
    }

    // Check if the difference is within 1 minute (adjust as needed)
    // return differenceInMinutes <= 1 && differenceInMinutes >= 0;
  };

  const checkSession = () => {
    console.log("pooling after 10 seconds"); 
    if (profile?.orgTimezone) {
      const cookies = new Cookies();
      const tokenExpiresAt = cookies.get('expires_at');
      const expirationTime = moment(tokenExpiresAt).tz(profile?.orgTimezone);
      const currentTime = moment.tz(profile?.orgTimezone);
      const differenceInSeconds = expirationTime.diff(currentTime, 'seconds');

      if (differenceInSeconds <= 90) {
        dispatch(
          pollUserInfo((status, message) => {
            if (status) {
              handleSessionExpireModal(tokenExpiresAt, profile?.orgTimezone);
            }
          }),
        );
      } else {
        handleSessionExpireModal(tokenExpiresAt, profile?.orgTimezone);
      }
    }
  };

  const sessionPooling = () => {
    setInterval(() => {
      checkSession();
    }, 10000);
  };

  const handleCloseSessionModal = () => {
    setShowSessionExpire(false);
  };

  return { sessionPooling, showSessionExpire, handleCloseSessionModal, countDownSeconds };
}

export default useSession;
