import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, FieldArray } from 'formik';
import moment from 'moment-timezone';
import _flatten from 'lodash/flatten';
import _cloneDeep from 'lodash/cloneDeep';
import 'moment-timezone';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import ViaIcon from '../../../../plans/components/ViaIcon';
import Button from '../../../../components/library/button';
import Headline from '../../../../components/library/headline';
import InputSelect from '../../../../components/library/inputSelect';
import InputDate from '../../../../components/library/inputDate';
import sBatchAddForms from './batchAddForms.scss';
import OneClickModal from '../oneClickModal';
import { getForms } from '../../../../../actions/formsActions';
export const enhance = compose(withStyles(sBatchAddForms));

function BatchAddForms(props) {
  const dispatch = useDispatch();
  const addForms = useSelector(state => state?.reports?.batchAddForm);
  const orgTimezone = useSelector(state => state?.profile?.orgTimezone);
  const [submitValues, setSubmitValues] = useState();
  const [showAddForms, setShowAddForms] = useState(false);
  const [submitting, setSubmitting] = useState();
  const publishedForms = useSelector(state => state?.forms?.forms);
  const [formTemplates, setFormTemplates] = useState();
  const [allFormTemplates, setAllFormTemplates] = useState();

  let counter = 0;
  const resetForm = () => {
    setDefaultValues(() => {
      let form_groupings = [
        {
          deadline: '',
          form_template_id: [],
        },
      ];

      return { form_groupings: form_groupings };
    });
  };
  const [defaultValues, setDefaultValues] = useState(() => {
    let form_groupings = [
      {
        deadline: '',
        form_template_id: '',
      },
    ];

    return { form_groupings: form_groupings };
  });

  let customStyles = {
    multiValue: provided => ({
      ...provided,
      textTransform: 'capitalize',
    }),
    option: (provided, state) => ({
      ...provided,
      textTransform: 'capitalize',
    }),
    container: (provided, state) => ({
      ...provided,
      textTransform: 'capitalize',
    }),
  };

  useEffect(() => {
    dispatch(getForms('General Form', 1));
  }, []);

  useEffect(
    () => {
      if (publishedForms?.data) {
        if (publishedForms?.data[0]?.template_type.toLowerCase() === 'general form') {
          const forms = publishedForms?.data?.map(template => ({
            value: template.id,
            label: template.name,
          }));

          setFormTemplates(forms);
          setAllFormTemplates(forms);
        }
      }
    },
    [publishedForms],
  );

  return (
    <>
      <ViaModal
        showing={props.show}
        onClose={() => {
          props.onClose(false);
        }}
        headerText="Add General Forms"
      >
        <div className={sBatchAddForms['batch-add-forms-modal']}>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={defaultValues}
            validate={(values, index) => {
              const errors = {};
              !submitting
                ? null
                : values?.form_groupings?.map((form, index) => {
                    if (form.form_template_id === null || form?.form_template_id === '') {
                      errors[`form_groupings[${index}].form_template_id`] = 'Form Required';
                    }
                  });
              return errors;
            }}
            onSubmit={values => {
              let formGroupingsArray = [];

              submitting
                ? values?.form_groupings.map(group => {
                    let date = null;
                    if (group?.form_deadline_date !== undefined) {
                      date = moment(group.form_deadline_date)
                        .tz(orgTimezone)
                        .format();
                    }

                    const groupTemplates = group?.form_template_id.map(form => {
                      return {
                        form_template_id: parseInt(form.value),
                      };
                    });

                    formGroupingsArray.push({
                      deadline: moment(date).isValid() ? date : '',
                      program_range_form_grouping_templates_attributes: groupTemplates,
                    });
                  })
                : null;

              const submitValues = {
                source: 'internal',
                program_range_ids: props.programIds,
                program_range_form_groupings_attributes: _flatten(formGroupingsArray),
              };

              setSubmitValues(submitValues);
              props.onClose(false);
              setShowAddForms(true);
            }}
          >
            {({ values, handleSubmit, errors, setFieldValue, handleChange, validateForm }) => (
              <Form>
                <FieldArray name="form_groupings">
                  {({ push }) => (
                    <>
                      {values?.form_groupings.map((form, index) => {
                        if (index === 0) {
                          counter = 0;
                        }
                        if (!form.delete) {
                          counter++;
                          return (
                            <div key={`form-${index}`} className={sBatchAddForms['forms-block']}>
                              <div className={sBatchAddForms.row}>
                                <div className={sBatchAddForms.item}>
                                  <Headline tag="h2" as="h3">
                                    {counter}. Form Group
                                    {index > 0 ? (
                                      <span
                                        className={sBatchAddForms.trash}
                                        onClick={() => {
                                          form.delete = true;
                                          validateForm();
                                        }}
                                      >
                                        <ViaIcon name="trash" color="darkText" size="xsmall" />
                                      </span>
                                    ) : null}
                                  </Headline>
                                </div>
                              </div>
                              <div className={sBatchAddForms.underlinedRow}>
                                <div className={sBatchAddForms.item}>
                                  <InputSelect
                                    id={`form_groupings[${index}].form_template_id`}
                                    name={`form_groupings[${index}].form_template_id`}
                                    labelText="General Form Name"
                                    aria-label="General Form Name"
                                    errorMessage={errors[`form_groupings[${index}].form_template_id`]}
                                    isRequired
                                    helpText=""
                                    isMulti
                                    styles={customStyles}
                                    value={values[`form_groupings[${index}].form_template_id`]}
                                    options={formTemplates}
                                    placeholder={
                                      values[`form_groupings[${index}].form_template_id`]
                                        ? form.form_template_id.label
                                        : ''
                                    }
                                    onChange={e => {
                                      form.form_template_id = e;
                                      const allForms = _cloneDeep(allFormTemplates);
                                      const selectedForms = [];

                                      values?.form_groupings?.forEach(group => {
                                        if (group?.form_template_id) {
                                          selectedForms?.push(...group?.form_template_id);
                                        }
                                      });

                                      const filteredForms = allForms.filter(
                                        all =>
                                          !selectedForms?.some(item => item.value.toString() === all.value.toString()),
                                      );

                                      setFormTemplates(filteredForms);
                                    }}
                                  />
                                </div>
                                <div className={sBatchAddForms.item}>
                                  <InputDate
                                    labelText="General Form Deadline Date"
                                    aria-label={`General Form Deadline Date - ${index}`}
                                    id={`form_groupings[${index}].form_deadline_date`}
                                    name={`form_groupings[${index}].form_deadline_date`}
                                    value={`form_groupings[${index}].form_deadline_date`}
                                    styles={form.form_deadline_date ? customStyles : null}
                                    placeholder={
                                      values[`form_groupings[${index}].form_deadline_date`]
                                        ? moment(values.form_deadline_date).format('MMM D, YYYY')
                                        : 'Select Date...'
                                    }
                                    onChange={e => {
                                      form.form_deadline_date = e;
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                      <div className={sBatchAddForms.formBlock}>
                        <div className={sBatchAddForms.formBlockContent}>
                          <span
                            className={sBatchAddForms.addForm}
                            onClick={() => {
                              push({
                                id: '',
                                deadline: '',
                                form_template_id: '',
                                delete: false,
                              });
                              setSubmitting(false);
                            }}
                          >
                            + Add Additional Forms
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </FieldArray>

                <div className={sBatchAddForms.footer}>
                  <div className={sBatchAddForms['button-content']}>
                    <Button
                      display="secondary"
                      kind="solid"
                      size="medium"
                      aria-label="Cancel Button"
                      onClick={() => {
                        props.onClose(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={sBatchAddForms['button-content']} onClick={setSubmitting(true)}>
                    <Button
                      display="primary"
                      kind="solid"
                      size="medium"
                      aria-label="Add General Forms Button"
                      type="submit"
                      onClick={values => {
                        handleSubmit(values);
                      }}
                    >
                      Add General Forms
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ViaModal>
      <OneClickModal
        headline={'Add General Forms'}
        show={showAddForms}
        onClose={() => {
          resetForm();
          setShowAddForms(false);
          props.setSelectedPrograms();
        }}
        termType={props?.termType}
        paragraphOne={`You are about to add one or more forms to ${
          props?.selectedPrograms?.length
        } internal ${props?.customAliases?.alias_program.toLowerCase()} ${
          props?.selectedPrograms?.length === 1 ? `term` : `terms`
        }. Forms can be edited anytime in ${props?.customAliases?.alias_program.toLowerCase()} settings.`}
        paragraphTwo={`Are you sure you want to add forms?`}
        cancelMessage={'Cancel'}
        confirmActionMessage={'Add General Forms'}
        source="add_form"
        selectedPrograms={props.programIds}
        resetForm={reset => setDefaultValues(reset)}
        actionState={addForms}
        addFormSuccessMessage={`Forms added to ${
          props?.selectedPrograms?.length
        }  ${props?.customAliases?.alias_program.toLowerCase()} ${
          props?.selectedPrograms?.length > 1 ? `terms` : `term`
        }`}
        resetForm={() => resetForm()}
        submitValues={submitValues}
        failureMessage={'Unable to update the selected term'}
      />
    </>
  );
}

BatchAddForms.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

export default enhance(BatchAddForms);
