import React from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../../components/library/viaModal';
import Button from '../../../../../components/library/button';
import Paragraph from '../../../../../components/library/paragraph';
import sBatchUnassignSuitcaseSuccess from './batchUnassignSuitcaseSuccess.scss';
export const enhance = compose(withStyles(sBatchUnassignSuitcaseSuccess));

function BatchUnassignSuitcaseSuccess(props) {
  const customAliases = useSelector(state => state.profile.customAliases);
  const failure =
    !!props.suitCaseNames &&
    !!props.failureMessage &&
    props.suitCaseNames.map(suitcase => {
      const failureIds = props.failureMessage.data.map(failure => {
        return failure.id;
      });

      return failureIds.includes(suitcase.program_range_id.toString()) ? suitcase : null;
    });

  return (
    <ViaModal
      showing={props.show}
      onClose={() => {
        props.onClose(true);
      }}
      headerText={`Batch Unassign Complete`}
      width="700px"
      upper={false}
    >
      <div className={sBatchUnassignSuitcaseSuccess.exitModalWrapper}>
        {!!props.failureMessage && props.failureMessage.data.length === 0 ? null : (
          <div
            className={
              !!props.failureMessage && props.failureMessage.failures.length === 0
                ? sBatchUnassignSuitcaseSuccess.exitModalContentFailuresHidden
                : sBatchUnassignSuitcaseSuccess.container
            }
          >
            <div className={sBatchUnassignSuitcaseSuccess.exitModalContent}>
              <Paragraph size="normal">
                <span className={sBatchUnassignSuitcaseSuccess.bold}>
                  {!!props.failureMessage && props.failureMessage.data.length}
                </span>{' '}
                suitcases have been unassigned from authorized terms. {customAliases.alias_travelers} will no longer be
                able to apply to the following terms:
              </Paragraph>
            </div>
            <div className={sBatchUnassignSuitcaseSuccess.exitModalContent}>
              <Paragraph size="normal" demiBold>
                {!!failure &&
                  failure
                    .filter(term => term !== null)
                    .map(term => `${term.term_name}, ${term.program_name}`)
                    .join(';')}
              </Paragraph>
            </div>
          </div>
        )}
        {!!props.failureMessage && props.failureMessage.failures.length === 0 ? null : (
          <div
            className={
              !!props.failureMessage && props.failureMessage.data.length === 0
                ? sBatchUnassignSuitcaseSuccess.exitModalContentSuccessHidden
                : sBatchUnassignSuitcaseSuccess.container
            }
          >
            <div className={sBatchUnassignSuitcaseSuccess.exitModalContent}>
              <Paragraph size="normal">
                <span className={sBatchUnassignSuitcaseSuccess.bold}>
                  {!!props.failureMessage && props.failureMessage.failures.length}
                </span>{' '}
                suitcases {!!props.failureMessage && props.failureMessage.failures.length === 1 ? 'was' : 'were'}{' '}
                <span className={sBatchUnassignSuitcaseSuccess.bold}>not</span> able to be unassigned from authorized
                terms due to at least one {customAliases.alias_traveler.toLowerCase()} application or form being
                attached:
              </Paragraph>
            </div>
            <div className={sBatchUnassignSuitcaseSuccess.exitModalContent}>
              <Paragraph size="normal" demiBold>
                {!!props.failureMessage && props.failureMessage.failures.join('; ')}
              </Paragraph>
            </div>
          </div>
        )}

        <div className={sBatchUnassignSuitcaseSuccess.exitModalFooter}>
          <div className={sBatchUnassignSuitcaseSuccess.exitModalFooterButtonContent}>
            <Button
              display="primary"
              kind="solid"
              size="medium"
              ariaLabel="Close Modal"
              onClick={() => {
                props.onClose(true);
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </ViaModal>
  );
}

BatchUnassignSuitcaseSuccess.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  confirm: propTypes.func.isRequired,
};

export default enhance(BatchUnassignSuitcaseSuccess);
