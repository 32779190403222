import React from 'react';
import styled from 'styled-components';

const ToolTipText = styled.span`
    background: #474747;
    color: #fff;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    width:203px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    opacity: 0;
    position: absolute;
    z-index: 4;
    text-transform: none;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    // -webkit-box-shadow: 1px 1px 1px 1px #c9c9c9;
    
    // box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
`;

const ToolTip = styled.div`
  color: inherit;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: inline-block;
  // padding-left: 5px;
  //margin-left:20px;
  position: relative;
  z-index: 1;

  &:hover {
    span {
        opacity: 1;
        z-index: 100;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
  }
  span:after {
    border-left: 6px solid transparent; /* Half the base width of the arrow */
    border-right: 6px solid transparent; /* Half the base width of the arrow */
    border-top: 10px solid #474747; /* Arrow color */
    border-width: 7px 7px 0;
    bottom: -7px;
    content: '';
    display: block;
    left: 60px;
    position: absolute;
    width: 0;
  }

  span {
    top: ${props => (props.top ? props.top : '-39px')};
    left: ${props => (props.left ? props.left : '-250%')};
    z-index: 100;
  }  
`;

export default function TooltipArrow({ children, disabled, toolTipText, top="-39px", left="-250%" }) {
  return (
    <ToolTip disabled={disabled} top={top} left={left}>
      {children}
      <ToolTipText>{toolTipText}</ToolTipText>
    </ToolTip>
  );
}
