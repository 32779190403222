exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes CMBZR{to{-webkit-transform:rotate(1turn)}}@keyframes CMBZR{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._1KrIN{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}._1KrIN .O1cbd{padding:30px 40px 0 12px}._1KrIN .O1cbd ._2BQzd{-ms-flex:1 1;flex:1 1;-ms-flex-direction:column;flex-direction:column;display:-ms-flexbox;display:flex}@media only screen and (max-width:599px){._1KrIN .O1cbd ._2BQzd{-ms-flex-direction:column;flex-direction:column}}._1KrIN .O1cbd ._2BQzd ._2l8-K,._1KrIN .O1cbd ._2BQzd ._3_VNu{-ms-flex:1 1;flex:1 1;-ms-flex-item-align:center;align-self:center}._1KrIN .O1cbd ._27DHb{-ms-flex:1 1;flex:1 1;-ms-flex-direction:column;flex-direction:column;display:-ms-flexbox;display:flex;margin-bottom:30px}@media only screen and (max-width:599px){._1KrIN .O1cbd ._27DHb{-ms-flex-direction:column;flex-direction:column}}._1KrIN .O1cbd ._27DHb ._3keeL{-ms-flex:1 1;flex:1 1;margin-left:55px}._1KrIN .O1cbd ._27DHb ._3keeL ._3xqQL{margin-top:30px}._1KrIN ._3qjhg{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;border-top:1px solid #ebebeb;padding:12px 40px;-ms-flex-pack:end;justify-content:flex-end}@media only screen and (max-width:599px){._1KrIN ._3qjhg{-ms-flex-direction:column;flex-direction:column}}._1KrIN ._3qjhg ._2_eZO{padding-left:16px}@media only screen and (max-width:599px){._1KrIN ._3qjhg ._2_eZO{padding-top:12px}}", ""]);

// exports
exports.locals = {
	"batch-add-term-name-auth": "_1KrIN",
	"section": "O1cbd",
	"row": "_2BQzd",
	"item": "_2l8-K",
	"instructions": "_3_VNu",
	"informationRow": "_27DHb",
	"suitCaseInformation": "_3keeL",
	"formGroupContainer": "_3xqQL",
	"footer": "_3qjhg",
	"button-content": "_2_eZO",
	"spin": "CMBZR"
};