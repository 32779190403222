exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";@-webkit-keyframes vaiW7{to{-webkit-transform:rotate(1turn)}}@keyframes vaiW7{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._1Jhay{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}._1Jhay ._1bQZU{margin:24px 40px}._1Jhay ._1bQZU p{margin-bottom:24px}._1Jhay ._3AsgS{border-top:1px solid #ebebeb}._1Jhay ._1IEyS{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;margin:12px 40px;text-align:center;padding-top:12px}@media only screen and (max-width:599px){._1Jhay ._1IEyS{-ms-flex-direction:column;flex-direction:column}}._1Jhay ._1IEyS ._3_uSQ{-ms-flex:1 1;flex:1 1;margin:12px 20px}._1Jhay ._1IEyS ._3_uSQ ._2ff9w{font-weight:600}._1Jhay .FyTsw{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;border-top:1px solid #ebebeb;padding:12px 40px;-ms-flex-pack:end;justify-content:flex-end}@media only screen and (max-width:599px){._1Jhay .FyTsw{-ms-flex-direction:column;flex-direction:column}}._1Jhay .FyTsw ._1_Jz6{padding-left:16px}@media only screen and (max-width:599px){._1Jhay .FyTsw ._1_Jz6{padding-top:12px}}", ""]);

// exports
exports.locals = {
	"batch-unassign-suitcase": "_1Jhay",
	"content": "_1bQZU",
	"form": "_3AsgS",
	"row": "_1IEyS",
	"item": "_3_uSQ",
	"bold": "_2ff9w",
	"footer": "FyTsw",
	"button-content": "_1_Jz6",
	"spin": "vaiW7"
};