import fetch from 'node-fetch';
import {
  ADMIN_LOGIN,
  CHECK_CURRENT_USER,
  CLEAR_EMAIL_ERROR,
  FETCH_ALL_ORGANIZATIONS,
  FETCH_ORGANIZATION,
  FORGOT_PASSWORD,
  LOGIN_FAILURE,
  SIGNUP_FAILURE,
  TRAVELER_SIGN_UP,
  FETCH_USER,
  RESET_AUTH_LOADING
} from './types';
import token from '../shared/helpers/token';
import { keys } from '../config/keys';
import { Cookies } from 'react-cookie';

export const checkCurrentUser = () => dispatch => {
  if (token) {
    dispatch({
      type: CHECK_CURRENT_USER,
      signedIn: true,
    });
  } else {
    dispatch({
      type: CHECK_CURRENT_USER,
      signedIn: false,
    });
  }
};

export const fetchAllOrganization = () => dispatch => {
  fetch(`${keys.baseUri}/api/client/all_organizations`)
    .then(response => response.json())
    .then(data => {
      dispatch({
        type: FETCH_ALL_ORGANIZATIONS,
        payload: data,
      });
    })
    .catch(error => console.error(error));
};

export const fetchOrganization = subdomain => dispatch => {
  fetch(`${keys.baseUri}/api/client/current_org/${subdomain}`)
    .then(response => {
      if (response.status === 404) {
        let url = new URL(window.location.href);
        let protocol = url.protocol;

        let hostParts = url.host.split('.');
        hostParts = hostParts.filter(element => /\S/.test(element));
        hostParts = (hostParts.splice(0, 1, 'www'), hostParts);

        let host = hostParts.join('.');
        let homePage = protocol + '//' + host;

        window.location.assign(homePage);
      }

      return response.json();
    })
    .then(data => {
      dispatch({
        type: FETCH_ORGANIZATION,
        payload: data,
      });
    })
    .catch(error => console.error(error));
};

export const loginAdmin = data => dispatch => {
  fetch(`${keys.baseUri}/api/client/sign_in`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      sign_in: { email: data.email, password: data.password },
    }),
  })
    .then(response => response.json())
    .then(res => {
      if (res.is_success == true) {
        // localStorage.setItem('token', res.data.token);
        localStorage.setItem('token', res.data.authentication_token);
        localStorage.setItem('user', res.data.user);
        localStorage.setItem('id', res.data.id);
        if (res.data.client_account) {
          localStorage.setItem('client', res.data.client_account.org_name);
          localStorage.setItem(
            'customBranding',
            JSON.stringify(res.data.branding_theme),
          );
        }
        dispatch({
          type: ADMIN_LOGIN,
          payload: res,
        });
      } else {
        dispatch({
          type: LOGIN_FAILURE,
          payload: res,
        });
      }
    })
    .catch(ex => {});
};



export const signUpTraveler = data => dispatch => {
  fetch(`${keys.baseUri}/api/traveler/sign_up`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user: {
        email: data.email,
        password: data.password,
        client_account_id: data.org,
      },
    }),
  })
    .then(response => response.json())
    .then(res => {
      if (res.is_success == true) {
        dispatch({
          type: TRAVELER_SIGN_UP,
          payload: res,
          signedIn: true,
          email: res.data.user,
        });
        return res;
      } else {
        dispatch({
          type: SIGNUP_FAILURE,
        });
      }
    });
};

export function set_forgot_password(data) {
  return {
    type: FORGOT_PASSWORD,
    payload: data,
  };
}

export function forgotPassword(data) {
  return function(dispatch) {
    return fetch(`${keys.baseUri}/api/password/forgot`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: data.email,
      }),
    })
      .then(response => response.json())
      .then(res => {
        dispatch(set_forgot_password(res));
      });
  };
}

export function fetchUser(resetToken) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/password/user/${resetToken}`, {
      headers: token,
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_USER,
          payload: data,
        }),
      )
      .catch(error => console.error(error));
  };
}

export function setPassword(password, userId, resetToken, jmpUrl = '' ) {
  const windowLocation = jmpUrl !== '' ? `/?jmp=${jmpUrl}`  : `/`;
  return function(dispatch) {
    return fetch(`${keys.baseUri}/api/password/update`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password: password,
        user_id: userId,
        reset_token: resetToken,
      }),
    })
      .then(response => response.json())
      .then(res => {
        if (res.status === 'Password Updated') {
          window.location.assign(`${windowLocation}`);
        }
      });
  };
}

export const clearEmailError = () => dispatch => {
  dispatch({
    type: CLEAR_EMAIL_ERROR,
  });
};

export const resetAuthState = () => dispatch => {
  dispatch({
    type: RESET_AUTH_LOADING,
  })
}

export function refreshToken(callback = null) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/authentication_tokens/refresh_token`, {
      headers: token,
      method: "PATCH"
    })
      .then(res => res.json())
      .then(data =>{
        const cookies = new Cookies(); 
        let tokenCookie = cookies.get("token"); 
        console.log("refresh token data is ", data); 
        if(tokenCookie){
          // tokenCookie = JSON.parse(tokenCookie);  
          let baseDomain = '';
          const hostName = window?.location?.hostname;
          const hostNameSplit = hostName.split('.');
          const hostLength = hostNameSplit?.length;
          const hostTLD = hostNameSplit[hostNameSplit?.length - 1].split(':')[0].toLowerCase();

          if (hostTLD === 'com') {
            // ex: via-trm.com
            baseDomain = `.${hostNameSplit[hostLength - 2]}.${hostTLD}`;
          } else if (hostTLD === 'nz') {
            //ex: go.360international.ac.nz
            baseDomain = `.${hostNameSplit[hostLength - 3]}.${hostNameSplit[hostLength - 2]}.${hostTLD}`;
          }
          console.log("token cookie is ", tokenCookie); 
          tokenCookie['X-User-Token'] = data.token.token; 
          cookies.set(
            'token',
            tokenCookie,
            { path: '/', sameSite: 'lax', domain: baseDomain },
          );
          cookies.set('expires_at', data.token.expires_at, { path: '/', sameSite: 'lax', domain: baseDomain });

          if(callback && typeof callback === "function"){
            callback(true, "Success"); 
          }

        }
      })
      .catch(error => {
        console.log("Error in refreshing token ", error); 
        if(callback && typeof callback === "function"){
          callback(false, error?.message); 
        }
      });
  };
}


export function pollUserInfo(callback = null) {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/authentication_tokens/poll_user_info`, {
      headers: token,
      method: "GET"
    })
      .then(res => res.json())
      .then(data =>{
        const cookies = new Cookies(); 
        let tokenCookie = cookies.get("token"); 
        console.log("poll token data is ", data); 
        if(tokenCookie){
          // tokenCookie = JSON.parse(tokenCookie);  
          let baseDomain = '';
          const hostName = window?.location?.hostname;
          const hostNameSplit = hostName.split('.');
          const hostLength = hostNameSplit?.length;
          const hostTLD = hostNameSplit[hostNameSplit?.length - 1].split(':')[0].toLowerCase();

          if (hostTLD === 'com') {
            // ex: via-trm.com
            baseDomain = `.${hostNameSplit[hostLength - 2]}.${hostTLD}`;
          } else if (hostTLD === 'nz') {
            //ex: go.360international.ac.nz
            baseDomain = `.${hostNameSplit[hostLength - 3]}.${hostNameSplit[hostLength - 2]}.${hostTLD}`;
          }
          console.log("token cookie is ", tokenCookie); 
          tokenCookie['X-User-Token'] = data.token.token; 
          cookies.set(
            'token',
            tokenCookie,
            { path: '/', sameSite: 'lax', domain: baseDomain },
          );
          cookies.set('expires_at', data.token.expires_at, { path: '/', sameSite: 'lax', domain: baseDomain });

          if(callback && typeof callback === "function"){
            callback(true, "Success"); 
          }

        }
      })
      .catch(error => {
        console.log("Error in polling token ", error); 
        if(callback && typeof callback === "function"){
          callback(false, error?.message); 
        }
      });
  };
}


