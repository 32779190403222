const saveState = (gridRef = null, keyName = '') => {
  if (gridRef && keyName?.length > 0) {
    const savedColumnState = gridRef.current.columnApi.getColumnState();
    const savedColumnGroupState = gridRef.current.columnApi.getColumnGroupState();
    const savedFilterState = gridRef.current.api.getFilterModel();
    const isPivotMode = gridRef.current.columnApi.isPivotMode();
    const savedQuickFilter = gridRef.current.api.filterManager.quickFilter;
    const gridStateSave = { savedColumnState, savedColumnGroupState, savedFilterState, isPivotMode, savedQuickFilter };

    if (typeof window !== undefined && localStorage) {
      localStorage.setItem(keyName, JSON.stringify(gridStateSave));
    }
  }
};

const restoreState = (gridRef = null, keyName = '') => {
  if (
    gridRef &&
    gridRef?.current?.api &&
    gridRef.current.columnApi &&
    keyName?.length > 0 &&
    typeof window !== undefined &&
    localStorage
  ) {
    const newGridState = localStorage.getItem(keyName);

    if (newGridState) {
      const parsedState = JSON.parse(newGridState);

      if (parsedState) {
        gridRef.current.columnApi.setColumnGroupState(parsedState?.savedColumnGroupState);
        gridRef.current.api.setFilterModel(parsedState?.savedFilterState);
        gridRef.current.columnApi.setPivotMode(parsedState?.isPivotMode);
        gridRef.current.columnApi.applyColumnState({
          state: parsedState?.savedColumnState,
          applyOrder: true,
        });
        gridRef.current.api.setQuickFilter(parsedState?.savedQuickFilter);
      }
    }
  }
};

const saveViewSelectValue = (value = null, keyName = '') => {
  const viewKey = `${keyName}-VIEWID`;

  if (typeof window !== undefined && localStorage) {
    localStorage.setItem(viewKey, value);
  }
};

const restoreViewSelectValue = (keyName = '') => {
  let viewKey = null;

  if (typeof window !== undefined && localStorage) {
    viewKey = localStorage.getItem(keyName);
  }
  return viewKey;
};

const saveTemplateSelectValue = (value = null, keyName = '') => {
  const viewKey = `${keyName}-TEMPLATEID`;

  if (typeof window !== undefined && localStorage) {
    localStorage.setItem(viewKey, value);
  }
};

const restoreTemplateSelectValue = (keyName = '') => {
  let viewKey = null;

  if (typeof window !== undefined && localStorage) {
    viewKey = localStorage.getItem(keyName);
  }
  return viewKey;
};

const saveOrganizationSelectValue = (value = null, keyName = '') => {
  const viewKey = `${keyName}-ORGANIZATIONID`;

  if (typeof window !== undefined && localStorage) {
    localStorage.setItem(viewKey, value);
  }
};

const restoreOrganizationSelectValue = (keyName = '') => {
  let viewKey = null;

  if (typeof window !== undefined && localStorage) {
    viewKey = localStorage.getItem(keyName);
  }
  return viewKey;
};

export {
  saveState,
  restoreState,
  saveViewSelectValue,
  restoreViewSelectValue,
  saveTemplateSelectValue,
  restoreTemplateSelectValue,
  saveOrganizationSelectValue,
  restoreOrganizationSelectValue,
};
