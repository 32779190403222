import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import moment from 'moment-timezone';
import 'moment-timezone';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import Button from '../../../../components/library/button';
import InputSelect from '../../../../components/library/inputSelect';
import InputDate from '../../../../components/library/inputDate';
import InputTimePicker from '../../../../components/library/inputTimePicker';
import sBatchAddApplication from './batchAddApplication.scss';
import OneClickModal from '../oneClickModal';
import { getForms } from '../../../../../actions/formsActions';

export const enhance = compose(withStyles(sBatchAddApplication));

function BatchAddApplication(props) {
  const dispatch = useDispatch();
  const addApplication = useSelector(state => state?.reports?.batchAddApplication);
  const orgTimezone = useSelector(state => state?.profile?.orgTimezone);
  const [addMargin, setAddMargin] = useState(false);
  const publishedApplications = useSelector(state => state?.forms?.forms);
  const [applicationTemplates, setApplicationTemplates] = useState();

  const [defaultValues, setDefaultValues] = useState(() => {
    let values = {
      application_deadline_date: '',
      application_deadline_time: '',
      application_template_id: null,
    };
    return values;
  });
  const resetForm = () => {
    setDefaultValues(() => {
      let values = {
        application_deadline_date: '',
        application_deadline_time: '',
        application_template_id: null,
      };
      return values;
    });
  };
  const [submitValues, setSubmitValues] = useState();
  const [showAddApplication, setShowAddApplication] = useState(false);

  let customStyles = {
    multiValue: provided => ({
      ...provided,
      textTransform: 'capitalize',
    }),
    option: (provided, state) => ({
      ...provided,
      textTransform: 'capitalize',
    }),
    container: (provided, state) => ({
      ...provided,
      textTransform: 'capitalize',
    }),
    singleValue: provided => ({
      ...provided,
      color: '#043544',
    }),
  };

  const bulletedMessage = (
    <ul>
      <li>Applications will be added to terms that do not already have an application</li>
      <li>Terms that already have an application template associated will not change</li>
      <li>Once an application template is added, it cannot be removed or replaced</li>
      <li>
        Application deadline and decision release timeline are editable in {props?.customAliases?.alias_program}{' '}
        settings
      </li>
    </ul>
  );

  const getFixedtzTime = () => {
    const timezone = orgTimezone;
    const currentDate = moment();
    const formattedDate = currentDate.format('MMMM DD, YYYY');
    const fixedDate = moment.tz(formattedDate, timezone).set({ hour: 23, minute: 59, second: 0, millisecond: 0 });
    const tzFixedTime = fixedDate.tz(timezone); // Convert to org timezone
    return tzFixedTime;
  };

  useEffect(() => {
    dispatch(getForms('Application', 1));
  }, []);

  useEffect(() => {
    if (publishedApplications?.data) {
      if (publishedApplications?.data[0]?.template_type.toLowerCase() === 'application') {
        const apps = publishedApplications?.data?.map(template => ({
          value: template.id,
          label: template.name,
        }));

        setApplicationTemplates(apps);
      }
    }
  }, [publishedApplications]);

  return (
    <>
      <ViaModal
        showing={props?.show}
        onClose={() => {
          props.onClose(false);
        }}
        headerText="Add Application"
      >
        <div className={sBatchAddApplication['batch-add-application-modal']}>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={defaultValues}
            validate={values => {
              const errors = {};
              if (!values.application_template_id) {
                errors.application_template_id = 'Application Required';
              }

              if (!!values.application_deadline_date && !values.application_deadline_time) {
                errors.application_deadline_time = 'Deadline Time Required';
              }

              if (!values.application_deadline_date && !!values.application_deadline_time) {
                errors.application_deadline_date = 'Deadline Date Required';
              }

              return errors;
            }}
            onSubmit={values => {
              let newDate = '';
              if (values?.application_deadline_date && values?.application_deadline_time) {
                newDate = moment
                  .tz(
                    values?.application_deadline_date.format('YYYY-MM-DD') +
                      ' ' +
                      moment(values?.application_deadline_time, 'h:mm:ss A').format('HH:mm:ss'),
                    orgTimezone,
                  )
                  .format('YYYY-MM-DD HH:mm:ss Z');
              }
              const submitValues = {
                source: 'internal',
                program_range_ids: props?.programIds,
                application_deadline: newDate,
                application_template_id: values?.application_template_id.value,
              };
              setSubmitValues(submitValues);
              props.onClose(false);
              setShowAddApplication(true);
            }}
          >
            {({ values, handleSubmit, errors }) => (
              <Form>
                <div className={sBatchAddApplication.row}>
                  <div className={sBatchAddApplication.item}>
                    <Field name="application_template_id">
                      {({ form }) => (
                        <InputSelect
                          id="application_template_id"
                          name="application_template_id"
                          aria-label="Application Name"
                          labelText="Application Name"
                          errorMessage={errors?.application_template_id}
                          isRequired
                          helpText=""
                          styles={customStyles}
                          value={values?.application_template_id}
                          options={applicationTemplates}
                          placeholder={values?.application_template_id?.label || ''}
                          onChange={e => form.setFieldValue('application_template_id', e)}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className={sBatchAddApplication.row}>
                  <div className={sBatchAddApplication.item}>
                    <Field name="application_deadline_date">
                      {({ form }) => (
                        <InputDate
                          id="application_deadline_date"
                          name="application_deadline_date"
                          aria-label="Application Deadline Date"
                          labelText="Application Deadline Date"
                          errorMessage={errors?.application_deadline_date}
                          helpText=""
                          setAddMargin={addMargin => setAddMargin(addMargin)}
                          value={values?.application_deadline_date}
                          placeholder={
                            values.application_deadline_date
                              ? moment(values.application_deadline_date).format('MMM D, YYYY')
                              : 'Select Date...'
                          }
                          onChange={e => {
                            let tzFixedTime = getFixedtzTime();
                            if (!values?.application_deadline_time) {
                              form.setFieldValue('application_deadline_time', tzFixedTime);
                            }
                            form.setFieldValue('application_deadline_date', e);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={sBatchAddApplication.item}>
                    <Field name="application_deadline_time">
                      {({ form }) => (
                        <InputTimePicker
                          id="application_deadline_time"
                          name="application_deadline_time"
                          aria-label="Application Deadline Time"
                          labelText="Application Deadline Time"
                          errorMessage={errors?.application_deadline_time}
                          helpText=""
                          value={values?.application_deadline_time}
                          onChange={e => form.setFieldValue('application_deadline_time', e)}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className={sBatchAddApplication.footer}>
                  <div className={sBatchAddApplication['button-content']}>
                    <Button
                      display="secondary"
                      aria-label="Cancel Button"
                      kind="solid"
                      size="medium"
                      onClick={() => {
                        props.onClose(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={sBatchAddApplication['button-content']}>
                    <Button display="primary" kind="solid" size="medium" type="submit" aria-label="Add Term Button">
                      Add
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ViaModal>
      <OneClickModal
        headline="Add Application"
        show={showAddApplication}
        termType={props?.termType}
        onClose={() => {
          setShowAddApplication(false);
          resetForm();
          props.setSelectedPrograms();
        }}
        paragraphOne={`You are about to add application info to ${
          props?.noApplications
        } internal ${props?.customAliases.alias_program.toLowerCase()} ${
          props?.noApplications === 1 ? 'term' : 'terms'
        }.`}
        bulletedMessage={bulletedMessage}
        paragraphTwo={`Are you sure you want to continue?`}
        cancelMessage={'Cancel'}
        confirmActionMessage={'Add Application'}
        source="add_application"
        selectedPrograms={props.programIds}
        actionState={addApplication}
        addApplicationSuccessMessage={`Application added to ${props?.noApplications} ${
          props?.noApplications === 1 ? 'term' : 'terms'
        }`}
        submitValues={submitValues}
        failureMessage={'Unable to update the selected term'}
      />
    </>
  );
}

BatchAddApplication.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

export default enhance(BatchAddApplication);
