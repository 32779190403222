exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _2_yPX{to{-webkit-transform:rotate(1turn)}}@keyframes _2_yPX{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._3q4EQ{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-direction:row;flex-direction:row;padding:25px;border-bottom:5px solid #ddaf64}._2yXLI{color:#373737;text-align:center;font-family:Nunito;font-size:25px;font-style:normal;font-weight:400;line-height:120%}._3WseM{outline:none;border:none;padding:0;background:none}._3a7OH{-ms-flex-direction:column;flex-direction:column}._3a7OH,.PjqCD{display:-ms-flexbox;display:flex}.PjqCD{-ms-flex-item-align:center;align-self:center;margin:0;padding:0;text-align:center}._3Oswj{width:30%;margin:auto}._1O2wj{width:70%;padding:30px}.HiQnh{color:#373737;font-family:Open Sans;font-size:16px;font-style:normal;font-weight:400;line-height:140%;letter-spacing:.08px;text-align:left}._1bfoE{background:#ebebeb;display:-ms-flexbox;display:flex;height:111px;-ms-flex-pack:center;justify-content:center;left:0;top:273px;width:100%;margin-bottom:-20px;gap:30px}.rNCs6{margin-top:auto;margin-bottom:auto}.IF8te{-ms-flex-item-align:center;align-self:center;color:#a82425;font-family:AvenirNextRegular,sans-serif;font-size:14px}.IF8te svg{-ms-flex-pack:center;justify-content:center;margin-bottom:2px;margin-right:5px}", ""]);

// exports
exports.locals = {
	"header": "_3q4EQ",
	"heading": "_2yXLI",
	"closeButton": "_3WseM",
	"modalContainer": "_3a7OH",
	"content": "PjqCD",
	"iconWrapper": "_3Oswj",
	"paragraphContainer": "_1O2wj",
	"paragraphWarning": "HiQnh",
	"buttonWrapper": "_1bfoE",
	"buttonItem": "rNCs6",
	"errorWrapper": "IF8te",
	"spin": "_2_yPX"
};