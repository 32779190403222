import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarToggler,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink,
} from 'reactstrap';
import s from '../Header.css';
import sModal from 'react-responsive-modal/styles.css';
import { compose } from 'redux';
import MessageIcon from '../messageBox';

import SelectAccessModal from '../select-access-modal';
import useSession from '../../../hooks/useSession';
import SessionExpireModal from '../../SessionExpireModal';

function TravelerHeader(props) {
  const [showSelectAccess, setShowSelectAccess] = useState(false);
  const { showSessionExpire, handleCloseSessionModal, countDownSeconds } = useSession();

  return (
    <nav>
      <Navbar className={s.header} expand="md">
        <img src={props.theme_logo} className={s.logo} alt="School Logo" />
        <NavbarToggler onClick={props.toggleMenu} className="navbar-light bg-light" />
        <Collapse isOpen={props.isOpen} navbar>
          <Nav navbar className={`${s.navsLinks}`}>
            <NavItem>
              <NavLink href="/traveler?tab=messages" style={{ color: '#fff' }}>
                Profile
              </NavLink>
            </NavItem>
            {props.clientFeatureList.legacy ? (
              <NavItem>
                <NavLink href="/traveler/programs" style={{ color: '#fff' }}>
                  {props.alias_programs}
                </NavLink>
              </NavItem>
            ) : null}
          </Nav>
          {props.clientFeatureList.travel_plans ? (
            <Nav navbar className={`${s.navItemLink}`}>
              <NavItem>
                <NavLink href="/plans/my-travel" style={{ color: '#fff' }}>
                  Via Travel
                </NavLink>
              </NavItem>
            </Nav>
          ) : null}
          <Nav
            navbar
            className="navs-links ml-auto"
            style={{
              marginLeft: 'auto',
              marginRight: 0,
            }}
          >
            <div tabIndex="-1" className="dropdown-divider" />
            <NavItem className={s.profileMainMenu}>
              <section className={`${s.userInfo}`}>
                {props.clientFeatureList.legacy ? (
                  <article className={`${s.dropdownSection} ${s.envelope}`}>
                    <Dropdown isOpen={props.messageDropdownOpen} toggle={props.toggleMessage}>
                      <DropdownToggle>
                        <MessageIcon color={props.customBranding.themes.theme_color_accent} />
                      </DropdownToggle>
                      <DropdownMenu className={s.messageMenu} right>
                        <DropdownItem>
                          {props.newMessagesLoading ? (
                            <span>Loading...</span>
                          ) : props.messages.length > 0 ? (
                            props.messages
                          ) : (
                            <span>Looks like you're all caught up! There are no new messages.</span>
                          )}
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </article>
                ) : null}
                <section className={`${s.dropdownSection} ${s.firstName} `}>
                  <h4>{props.profileInfo.first_name}</h4>
                </section>
                <section className={`${s.dropdownSection} ${s.profileMenu} `}>
                  <Dropdown isOpen={props.dropdownOpen} toggle={props.toggle} data-value="menu">
                    <DropdownToggle className={s.dropdownImage}>
                      <img
                        className={s.dropdownToggle}
                        src={props.avatarUrl}
                        alt="User avatar"
                        onError={props.addDefaultSrc}
                      />
                    </DropdownToggle>
                    <DropdownMenu right data-value="menu" className={s.dropdownMenu}>
                      <div
                        tabIndex={0}
                        className={s.menuItem}
                        onClick={() => {
                          window.location.href = '/traveler/settings';
                        }}
                        onKeyDown={e => {
                          if (e.keyCode === 32 || e.keyCode === 13) {
                            window.location.href = '/traveler/settings';
                          }
                        }}
                      >
                        <img src="/img/header/settings.svg" width="17px" alt="Settings" />
                        <span className={s.menuItemValue}>Settings</span>
                      </div>
                      {props.admin_access && (
                        <div
                          tabIndex={0}
                          className={s.menuItem}
                          onClick={() => {
                            setShowSelectAccess(true);
                          }}
                          onKeyDown={e => {
                            if (e.keyCode === 32 || e.keyCode === 13) {
                              setShowSelectAccess(true);
                            }
                          }}
                        >
                          <img src="/img/header/swap.svg" width="17px" alt="Change Role" />
                          <span className={s.menuItemValue}>Change Role</span>
                        </div>
                      )}
                      <div
                        tabIndex={0}
                        className={s.menuItem}
                        onClick={() => {
                          props.signOut();
                        }}
                        onKeyDown={e => {
                          if (e.keyCode === 32 || e.keyCode === 13) {
                            props.signOut();
                          }
                        }}
                      >
                        <img src="/img/header/sign-out.svg" width="20px" alt="Sign Out" />
                        <span className={s.menuItemValue}>Sign Out</span>
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </section>
              </section>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>

      <SelectAccessModal
        show={showSelectAccess}
        onClose={() => setShowSelectAccess(false)}
        freemium={props.freemium}
        admin_access={props.admin_access}
        admin_sign_in={props.admin_sign_in}
        clientFeatureList={props.clientFeatureList}
      />

      {showSessionExpire && <SessionExpireModal show={showSessionExpire} handleClose={handleCloseSessionModal} countDownSeconds={countDownSeconds} />}
    </nav>
  );
}

TravelerHeader.propTypes = {
  addDefaultSrc: PropTypes.func,
  alias_programs: PropTypes.string,
  avatarUrl: PropTypes.string,
  clientFeatureList: PropTypes.object,
  customBranding: PropTypes.object,
  dropdownOpen: PropTypes.bool,
  isOpen: PropTypes.bool,
  messageDropdownOpen: PropTypes.bool,
  messages: PropTypes.any,
  newMessagesLoading: PropTypes.bool,
  onError: PropTypes.func,
  profileInfo: PropTypes.object,
  signOut: PropTypes.func,
  theme_logo: PropTypes.string,
  toggle: PropTypes.func,
  toggleMenu: PropTypes.func,
  toggleMessage: PropTypes.func,
  travelerMessage: PropTypes.func,
};

export default compose(withStyles(s, sModal))(TravelerHeader);
