import React, { useEffect, useRef, useState } from 'react';
import sReactPDF from './reactPDF.scss';
import { Document, Page } from 'react-pdf';
import Arrow from '../../../public/img/arrow.svg';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ScreenReaderHeadingComponent from '../../sites/travelerProfile/components/heading/ScreenReaderHeadingComponent/index.js';
import Modal from 'react-bootstrap/Modal';
import closeIcon from '../../shared/images/close.png';

function ReactPDF(props) {
  const modalRef = useRef(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    if (props.show && modalRef.current) {
      modalRef.current.focus();
    }
  }, [props.show]);   

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.handleClose();
      }}
      aria-label='Preview Attachment'
      size="lg"
    >
      <div
      ref={modalRef}
      role="dialog"
      tabIndex="-1">
        <Modal.Header className={sReactPDF.modelHeader}>
          <div>
            <Modal.Title>Preview</Modal.Title>
            <ScreenReaderHeadingComponent text="Preview" />
          </div>

          <div>
            <button
              id="preview-close-button"
              type="button"
              tabIndex={0}
              className={sReactPDF.modelCloseBtn}
              onClick={() => {
                props.handleClose();
              }}
            >
              <img src={closeIcon} alt="Close" />
            </button>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className={sReactPDF['react-pdf']}>
            <Document file={props.file} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} height={560} className={sReactPDF.pdfPage} />
            </Document>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className={sReactPDF.footer}>
            <p className={sReactPDF.pageContainer}>
              {pageNumber === 1 || numPages === 1 ? null : (
                <img
                  src={Arrow}
                  alt="Previous Page"
                  className={sReactPDF.backArrow}
                  onClick={() => {
                    setPageNumber(pageNumber - 1);
                  }}
                />
              )}
              Page {pageNumber} of {numPages}
              {pageNumber === numPages ? null : (
                <img
                  src={Arrow}
                  alt="Next Page"
                  className={sReactPDF.forwardArrow}
                  onClick={() => {
                    setPageNumber(pageNumber + 1);
                  }}
                />
              )}
            </p>
          </div>
        </Modal.Footer>
      </div>  
    </Modal>
  );
}

export default withStyles(sReactPDF)(ReactPDF);