exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2e1cx{font-family:Nunito;font-size:25px;font-weight:400;line-height:30px;text-align:left;margin-bottom:0}._3mUMh{padding:38px 60px}._1osCf{font-family:Open Sans;font-size:16px;font-weight:400;line-height:22.4px;letter-spacing:.005em;text-align:center}._1JXG6{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between;width:100%;-ms-flex-align:center;align-items:center}._2BA1h{width:25px;height:25px}._3oxix{background:transparent;border:none;padding:0}.uPJas{background:#ebebeb;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:right;justify-content:right;gap:15px}._1H1u2{background:transparent;border:none;padding:0}._1H1u2,._10N5M{font-family:Open Sans;font-size:16px;font-weight:600;line-height:19.2px;letter-spacing:.06em}._10N5M{padding:12px 25px;gap:10px;border-radius:5px;background:var(--theme_color_accent);border-color:var(--theme_color_accent);border:1px solid var(--theme_color_accent);color:#fff}._10N5M:disabled,._10N5M:hover{opacity:.7}", ""]);

// exports
exports.locals = {
	"heading": "_2e1cx",
	"container": "_3mUMh",
	"text": "_1osCf",
	"header": "_1JXG6",
	"closeIcon": "_2BA1h",
	"closeBtn": "_3oxix",
	"footer": "uPJas",
	"logoutBtn": "_1H1u2",
	"primaryBtn": "_10N5M"
};