import React, { useState, useEffect } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './index.css';
import autoSavingIcon from '../../shared/images/auto-saving.png';
import autoSavedIcon from '../../shared/images/auto-saved.png';
import autoSavedTryToConnectIcon from '../../shared/images/auto-save-connect.png';
import useOnline from '../../hooks/useOnline';

function AutoSaveState({ loading }) {
  const { isOnline } = useOnline();
  //   const [isOnline, setIsOnline] = useState(navigator.onLine);

  //   useEffect(() => {
  //     const handleOnline = () => {
  //       setIsOnline(true);
  //     };

  //     const handleOffline = () => {
  //       setIsOnline(false);
  //     };

  //     window.addEventListener('online', handleOnline);
  //     window.addEventListener('offline', handleOffline);

  //     // Cleanup event listeners on component unmount
  //     return () => {
  //       window.removeEventListener('online', handleOnline);
  //       window.removeEventListener('offline', handleOffline);
  //     };
  //   }, []);

  if (!isOnline) {
    return (
      <div className={styles.container}>
        <img src={autoSavedTryToConnectIcon} className={styles.savedImg} />
        <span className={styles.label}>Trying to connect</span>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {loading && <img src={autoSavingIcon} className={styles.savedImg} />}
      {!loading && <img src={autoSavedIcon} className={styles.savedImg} />}
      <span className={styles.label}>
        {loading && 'Saving...'}
        {!loading && 'Saved'}
      </span>
    </div>
  );
}

export default withStyles(styles)(AutoSaveState);
