import React, { useEffect, useState, memo } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { useDispatch, useSelector } from 'react-redux';
import _concat from 'lodash/concat';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { buildSelectOptions } from '../../../../../shared/helpers/General';
import FilterMultiSelect from '../../filterTypes/filterMultiSelect';
import FilterMultiSelectWithIs from '../../filterTypes/filterMultiSelectWithIs';
import sFilterAccordion from '../../../../../../public/css/filterAccordion.scss';
import { getPrograms } from '../../../../../actions/automationActions';
export const enhance = compose(withStyles(sFilterAccordion));

function ProgramNameFilter(props) {
  const dispatch = useDispatch();
  const programNames = useSelector(state => state.automation.programs);
  const [expanded, setExpanded] = useState(false);
  const [manualExpanded, setManualExpanded] = useState(false);
  const [sourceOptions, setSourceOptions] = useState([]);

  useEffect(() => {
    if (props.source) {
      if (props.source == 'unauthorized') {
        dispatch(getPrograms('', '', false, props.source, props.org_id));
      } else {
        dispatch(getPrograms('', '', false, props.source));
      }
    } else {
      dispatch(getPrograms('', '', true));
    }
  }, []);

  useEffect(() => {
    if (props.source) {
      if (props.source == 'unauthorized') {
        dispatch(getPrograms('', '', false, props.source, props.org_id));
      }
    }
  }, [props.source, props.org_id]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      if (programNames) {
        let isBlank = ['Blank'];
        let unpublished = [];
        let published = [];
        let draft = [];
        let archived = [];
        let unauthorized = [];
        programNames?.data?.map(item => {
          if (item.title.length > 0) {
            if (item.unauthorize) {
              unauthorized.push(item.title);
            } else {
              if (item.status == 'published') {
                published.push(item.title);
              } else if (item.status == 'unpublished') {
                unpublished.push(item.title);
              } else if (item.status == 'draft') {
                draft.push(item.title);
              } else {
                archived.push(item.title);
              }
            }
          }
        });

        const isBlankValues = buildSelectOptions(isBlank, false, false);
        const unpublishedValues = buildSelectOptions(unpublished, true, true);
        const publishedValues = buildSelectOptions(published, true, true);
        const draftValues = buildSelectOptions(draft, true, true);
        const archivedValues = buildSelectOptions(archived, true, true);
        const unauthorizedValues = buildSelectOptions(unauthorized, true, true);
        const groupOptions = [
          { label: '', options: isBlankValues },
          { label: 'Published', options: publishedValues },
          { label: 'Unpublished', options: unpublishedValues },
          { label: 'Archived', options: archivedValues },
          { label: 'Draft', options: draftValues },
          { label: 'Unauthorized', options: unauthorizedValues },
        ];

        setSourceOptions(groupOptions);
      }
    }

    return () => {
      unmounted = true;
    };
  }, [programNames]);

  useEffect(() => {
    if (props.selectedValues) {
      if (props.selectedValues[1] && props.selectedValues[1].length > 0) {
        props.selectedValues[1] = props.selectedValues[1].sort();
      }

      const calcExpanded = manualExpanded || props.selectedValues.length > 0 ? true : false;
      setExpanded(calcExpanded);
    }

    let valueNames = [];
    props.sourceData.forEach(item => {
      if (!!item && item.length > 0) {
        valueNames = _concat(valueNames, item);
      }
    });
    const optionValues = buildSelectOptions(valueNames, true, true);
    const groupOptions = [{ label: '', options: optionValues }];

    //setSourceOptions(groupOptions);
  }, [props]);

  const selectedValues = values => {
    props.change(values);
  };

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      onChange={() => {
        setExpanded(!expanded);
        setManualExpanded(!manualExpanded);
      }}
    >
      <AccordionItem uuid={props.id} dangerouslySetExpanded={expanded}>
        <AccordionItemHeading>
          <AccordionItemButton>{props.displayText}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          {props.withIs ? (
            <FilterMultiSelectWithIs
              id={props.id}
              sourceData={sourceOptions}
              selectedValues={props.selectedValues}
              change={e => selectedValues(e)}
              labelText={''}
            />
          ) : (
            <FilterMultiSelect
              id={props.id}
              sourceData={sourceOptions}
              selectedValues={props.selectedValues}
              change={e => selectedValues(e)}
              labelText={''}
            />
          )}
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

ProgramNameFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
  sourceData: propTypes.array.isRequired,
  withIs: propTypes.bool,
};

ProgramNameFilter.defaultProps = {
  displayText: 'Program Name',
  selectedValues: [],
  withIs: false,
};

export default enhance(ProgramNameFilter);

export const ProgramNameFilterMemo = memo(
  compose(withStyles(sFilterAccordion))(function ProgramNameFilterMemo({
    selectedValues = null,
    onFilterChange,
    sourceData = [],
    withIs = false,
  }) {
    return (
      <ProgramNameFilter
        id={'program_name'}
        selectedValues={selectedValues}
        change={values => {
          onFilterChange('program_name', values);
        }}
        sourceData={sourceData}
        withIs={withIs}
      />
    );
  }),
);
