exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1MpGO{font-family:Open Sans;font-size:48px;font-weight:700;line-height:67.2px;letter-spacing:.005em;text-align:center}", ""]);

// exports
exports.locals = {
	"clock": "_1MpGO"
};