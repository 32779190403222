import React from 'react';
import Select, { components } from 'react-select';
import propTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function GenericActionSelect(props) {
  const DropdownIndicator = dropdownProps => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...dropdownProps}>
          <FontAwesomeIcon
            color={'#373737'}
            icon={dropdownProps.selectProps.menuIsOpen ? 'angle-up' : 'angle-down'}
            size="lg"
          />
        </components.DropdownIndicator>
      )
    );
  };

  const customStyles = {
    control: (styles, { isDisabled }) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? '#E7E7E7' : '#D2E4ED',
        color: '#373737',
        borderRadius: '5px',
        borderColor: isDisabled ? '#E7E7E7' : '#D2E4ED',
        padding: '0 16px',
        fontSize: '15px',
        '&:hover': {
          cursor: isDisabled ? 'not-allowed' : 'pointer',
        },
        fontFamily: 'Open Sans',
        fontWeight: '700',
        border: isDisabled ? '1px solid #999999' : '1px solid #373737',
      };
    },
    menuList: styles => {
      return {
        ...styles,
        maxHeight: 'none',
        marginTop: '-8px',
        backgroundColor: '#f6f7f9',
        minWidth: '210px',
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: !!data.backgroundColor ? data.backgroundColor : isFocused ? '#ebebeb' : '#f6f7f9',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        color: !!data.textColor ? data.textColor : '#043544',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        paddingTop: '14px',
        minWidth: '210px',
        '&:hover': {
          backgroundColor: '#ebebeb',
        },
      };
    },
    placeholder: styles => {
      return {
        ...styles,
        color: '#373737',
        fontFamily: 'Open Sans',
      };
    },
    singleValue: styles => {
      return {
        ...styles,
        color: '#373737',
        fontFamily: 'Open Sans',
      };
    },
  };

  return !props.show ? null : (
    <Select
      {...props}
      options={props.options}
      placeholder={props.placeholder}
      isSearchable={false}
      styles={customStyles}
      openMenuOnFocus={true}
      getOptionLabel={e => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {e.icon}
          <span>{e.label}</span>
        </div>
      )}
      components={{ IndicatorSeparator: () => null, DropdownIndicator }}
    />
  );
}

GenericActionSelect.propTypes = {
  options: propTypes.array.isRequired,
  placeholder: propTypes.string,
};

GenericActionSelect.defaultProps = {
  placeholder: 'Program Actions',
  show: true,
};

export default GenericActionSelect;
