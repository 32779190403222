import React from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../../components/library/viaModal';
import Button from '../../../../../components/library/button';
import Paragraph from '../../../../../components/library/paragraph';
import sBatchUnassignSuitcaseWarning from './batchUnassignSuitcaseWarning.scss';
export const enhance = compose(withStyles(sBatchUnassignSuitcaseWarning));

function BatchUnassignSuitcaseWarning(props) {
  const customAliases = useSelector(state => state.profile.customAliases);

  return (
    <ViaModal
      showing={props.show}
      showCustomImage
      customImageType="warning"
      onClose={() => {
        props.onClose(true);
      }}
      headerText={`Warning: ${customAliases.alias_travelers} will no longer be able to apply`}
      width="700px"
      upper={false}
    >
      <div className={sBatchUnassignSuitcaseWarning.exitModalWrapper}>
        <div className={sBatchUnassignSuitcaseWarning.exitModalContent}>
          <Paragraph size="normal">
            <ul>
              <li className={sBatchUnassignSuitcaseWarning.bullet}>
                Suitcases assigned to authorized terms with {customAliases.alias_traveler.toLowerCase()} applications
                and forms will NOT be unassigned
              </li>
              <li className={sBatchUnassignSuitcaseWarning.bullet}>
                {customAliases.alias_travelers} will still be able to request to apply to authorized terms without
                suitcases assigned
              </li>
            </ul>
          </Paragraph>
        </div>
        <div className={sBatchUnassignSuitcaseWarning.exitModalFooter}>
          <div className={sBatchUnassignSuitcaseWarning.exitModalFooterButtonContent}>
            <Button
              display="secondary"
              kind="solid"
              size="medium"
              ariaLabel="Cancel and Exit"
              onClick={() => {
                props.onClose(true);
              }}
            >
              Cancel & Exit
            </Button>
          </div>
          <div className={sBatchUnassignSuitcaseWarning.exitModalFooterButtonContent}>
            <Button
              display="clientAccent"
              kind="solid"
              size="medium"
              ariaLabel="Confirm & Unassign Suitcases"
              onClick={() => {
                props.confirm();
                props.onClose(true);
              }}
            >
              Confirm & Unassign Suitcases
            </Button>
          </div>
        </div>
      </div>
    </ViaModal>
  );
}

BatchUnassignSuitcaseWarning.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  confirm: propTypes.func.isRequired,
};

export default enhance(BatchUnassignSuitcaseWarning);
