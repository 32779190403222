import fetch from 'node-fetch';
import { keys } from '../config/keys';
import token from '../shared/helpers/token';
import {
  CREATE_NEW_PROGRAM_CLEAR,
  CREATE_NEW_PROGRAM_REQUEST,
  CREATE_NEW_PROGRAM_SUCCESS,
  CREATE_NEW_PROGRAM_FAILURE,
  DELETE_DRAFT_PROGRAM_REQUEST,
  DELETE_DRAFT_PROGRAM_SUCCESS,
  DELETE_DRAFT_PROGRAM_FAILURE,
  GET_PROGRAM_REQUEST,
  GET_PROGRAM_SUCCESS,
  GET_PROGRAM_FAILURE,
  MODIFY_PROGRAM_CLEAR,
  MODIFY_PROGRAM_REQUEST,
  MODIFY_PROGRAM_SUCCESS,
  MODIFY_PROGRAM_FAILURE,

  ADD_EDIT_PROGRAM_BROCHURE_SECTION_REQUEST,
  ADD_EDIT_PROGRAM_BROCHURE_SECTION_SUCCESS,
  ADD_EDIT_PROGRAM_BROCHURE_SECTION_FAILURE,
  ADD_EDIT_PROGRAM_BROCHURE_SECTION_CLEAR,

  BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_REQUEST,
  BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_SUCCESS,
  BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_FAILURE,
  BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_CLEAR,

  BATCH_DELETE_PROGRAM_BROCHURE_SECTION_REQUEST,
  BATCH_DELETE_PROGRAM_BROCHURE_SECTION_SUCCESS,
  BATCH_DELETE_PROGRAM_BROCHURE_SECTION_FAILURE,
  BATCH_DELETE_PROGRAM_BROCHURE_SECTION_CLEAR,
  DELETE_PROGRAM_BROCHURE_SECTION_REQUEST,
  DELETE_PROGRAM_BROCHURE_SECTION_SUCCESS,
  DELETE_PROGRAM_BROCHURE_SECTION_FAILURE,
  DELETE_PROGRAM_BROCHURE_SECTION_CLEAR

} from './types';

export function createNewProgramModify() {
  return function(dispatch) {
    dispatch({ type: CREATE_NEW_PROGRAM_REQUEST });
    fetch(`${keys.baseUri}/api/programs`, {
      method: 'POST',
      headers: token,
    })
      .then(res => {
        if (res.status !== 201 && res.status !== 204) {
          res.json().then(data => {
            dispatch({
              type: CREATE_NEW_PROGRAM_FAILURE,
            });
          });
        } else {
          res.json().then(data => {
            dispatch({
              type: CREATE_NEW_PROGRAM_SUCCESS,
              payload: data,
            });
          });
        }
      });
  };
}

export function createProgramModifyClear() {
  return function (dispatch) {
    dispatch({type: CREATE_NEW_PROGRAM_CLEAR});
  };
}

export function deleteProgramModify(programId) {
  return function(dispatch) {
    dispatch({ type: DELETE_DRAFT_PROGRAM_REQUEST });
    fetch(`${keys.baseUri}/api/programs/${programId}`, {
      method: 'DELETE',
      headers: token,
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 204) {
          dispatch({
            type: DELETE_DRAFT_PROGRAM_FAILURE,
          });
        } else {
          dispatch({
            type: DELETE_DRAFT_PROGRAM_SUCCESS,
          });
        }
      });
  };
}

export function getProgramModify(programId) {
  return function(dispatch) {
    dispatch({ type: GET_PROGRAM_REQUEST });
    fetch(`${keys.baseUri}/api/programs/${programId}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => {
        if (res.status !== 200) {
          res.json().then(data => {
            dispatch({
              type: GET_PROGRAM_FAILURE,
            });
          });
        } else {
          res.json().then(data => {
            dispatch({
              type: GET_PROGRAM_SUCCESS,
              payload: data,
            });
          });
        }
      });
  };
}

export function updateProgramModify(programId, data) {
  return function(dispatch) {
    dispatch({ type: MODIFY_PROGRAM_REQUEST });
    fetch(`${keys.baseUri}/api/programs/${programId}`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify({ program: data }),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: MODIFY_PROGRAM_SUCCESS,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({
          type: MODIFY_PROGRAM_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function updateProgramModifyClear() {
  return function (dispatch) {
    dispatch({type: MODIFY_PROGRAM_CLEAR});
  };
}

export function batchAddEditCustomBrochureSection (request) {
  return function(dispatch) {
    dispatch({ type: BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_REQUEST });
    fetch(`${keys.baseUri}/api/program_batch_actions/add_edit_program_brochure_section`, {
      method: 'PUT',
      headers: token,
      body: JSON.stringify(request),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_SUCCESS,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({
          type: BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function batchAddEditCustomBrochureSectionClear() {
  return function (dispatch) {
    dispatch({type: BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_CLEAR});
  };
}

export function addEditCustomBrochureSection (request) {
  return function(dispatch) {
    dispatch({ type: ADD_EDIT_PROGRAM_BROCHURE_SECTION_REQUEST });
    fetch(`${keys.baseUri}/api/client/program_brochure_sections`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(request),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: ADD_EDIT_PROGRAM_BROCHURE_SECTION_SUCCESS,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({
          type: ADD_EDIT_PROGRAM_BROCHURE_SECTION_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function addEditCustomBrochureSectionClear() {
  return function (dispatch) {
    dispatch({type: ADD_EDIT_PROGRAM_BROCHURE_SECTION_CLEAR});
  };
}

export function batchDeleteCustomBrochureSection(request) {
  return function(dispatch) {
    dispatch({ type: BATCH_DELETE_PROGRAM_BROCHURE_SECTION_REQUEST });
    fetch(`${keys.baseUri}/api/program_batch_actions/delete_program_brochure_section`, {
      method: 'DELETE',
      headers: token,
      body: JSON.stringify(request),
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 204) {
          dispatch({
            type: BATCH_DELETE_PROGRAM_BROCHURE_SECTION_FAILURE,
          });
        } else {
          dispatch({
            type: BATCH_DELETE_PROGRAM_BROCHURE_SECTION_SUCCESS,
          });
        }
      });
  };
}
export function batchDeleteCustomBrochureSectionClear() {
  return function (dispatch) {
    dispatch({type: BATCH_DELETE_PROGRAM_BROCHURE_SECTION_CLEAR});
  };
}
export function deleteCustomBrochureSection(id) {
  return function(dispatch) {
    dispatch({ type: DELETE_PROGRAM_BROCHURE_SECTION_REQUEST });
    fetch(`${keys.baseUri}/api/client/program_brochure_sections/${id}`, {
      method: 'DELETE',
      headers: token,
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 204) {
          dispatch({
            type: DELETE_PROGRAM_BROCHURE_SECTION_FAILURE,

          });
        } else {
          dispatch({
            type: DELETE_PROGRAM_BROCHURE_SECTION_SUCCESS,
            payload: "Brochure section deleted",
          });
        }
      });
  };
}

export function deleteCustomBrochureSectionClear() {
  return function (dispatch) {
    dispatch({type: DELETE_PROGRAM_BROCHURE_SECTION_CLEAR});
  };
}