import React, { useState, useEffect } from 'react';
import styles from './index.css'; 
import withStyles from 'isomorphic-style-loader/lib/withStyles';

const CountDownTimer = ({ countDownSeconds = 60 }) => {
  // Initialize state to store the remaining time in seconds
  const [timeLeft, setTimeLeft] = useState(countDownSeconds);

  useEffect(() => {
    // Exit early if the countdown has reached zero

    console.log("timeLeft is ", timeLeft); 

    if (timeLeft === 0){
      localStorage.setItem("is_session_expired", "1");
      window.location.assign(`/clear_cookies`);
      return;
    } 
    

    // Set up a timer that runs every second
    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    // Cleanup function to clear the interval when the component unmounts or timeLeft changes
    return () => clearInterval(timerId);
  }, [timeLeft]);

  // Convert the remaining time into minutes and seconds format (MM:SS)
  const formatTime = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;

    // console.log("seconds left ", seconds); 
    if(seconds === 0){
      // console.log("redirection is called"); 
      // window.location.assign(`/clear_cookies`);
    }


    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  return (
    <div className={styles.clock}>{formatTime()}</div>
  );
};

export default withStyles(styles)(CountDownTimer);
