import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import Button from '../../../../components/library/button';
import BatchUnassignSuitcaseWarning from './batchUnassignSuitcaseWarning';
import BatchUnassignSuitcaseSuccess from './batchUnassignSuitcaseSuccess';
import sBatchUnassignSuitcase from './batchUnassignSuitcase.scss';
import Paragraph from '../../../../components/library/paragraph';
import { unassignSuitcases } from '../../../../../actions/reportsActions';

export const enhance = compose(withStyles(sBatchUnassignSuitcase));
function BatchUnassignSuitcase(props) {
  const [submitValues, setSubmitValues] = useState();
  const unassignSuitcase = useSelector(state => state.reports.unassignSuitcases);
  const [showWarning, setShowWarning] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [failureMessage, setFailureMessage] = useState();
  const [suitCaseNames, setSuitcaseNames] = useState();
  const dispatch = useDispatch();

  useEffect(
    () => {
      if (!!unassignSuitcase.data) {
        if (!!unassignSuitcase.data.failures) {
          setShowSuccessModal(true);
          setFailureMessage(unassignSuitcase.data);
        } else {
          setShowSuccessModal(true);
          props.onClose(false);
        }
      }
    },
    [unassignSuitcase],
  );

  return (
    <>
      <ViaModal
        showing={props.show}
        onClose={() => {
          props.onClose(false);
          setFailureMessage();
        }}
        headerText={'Unassign Suitcase'}
        width="700px"
      >
        <div className={sBatchUnassignSuitcase['batch-unassign-suitcase']}>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={values => {
              const submitValues = {
                program_range_ids: props.programIds,
                source: 'authorized',
              };
              setSuitcaseNames(props.suitCaseNames);
              setSubmitValues(submitValues);
              props.onClose(false);
              setShowWarning(true);
            }}
          >
            {() => (
              <Form className={sBatchUnassignSuitcase.form}>
                <div className={sBatchUnassignSuitcase.row}>
                  <div className={sBatchUnassignSuitcase.item}>
                    <Paragraph>
                      You are about to unassign the following suitcases from
                      <span className={sBatchUnassignSuitcase.bold}> {props.selectedPrograms.length} </span>
                      {props.selectedPrograms.length < 1 ? 'term' : 'terms'}.
                    </Paragraph>
                  </div>
                </div>

                <div className={sBatchUnassignSuitcase.row}>
                  <div className={sBatchUnassignSuitcase.item}>
                    <Paragraph avenirNextMedium>
                      {props.suitCaseNames.map(term => (!!term.term_name ? term.term_name : null)).join('; ')}
                    </Paragraph>
                  </div>
                </div>

                <div className={sBatchUnassignSuitcase.row}>
                  <div className={sBatchUnassignSuitcase.item}>
                    <Paragraph>
                      Suitcases can only be unassigned from terms with no{' '}
                      {props.customAliases.alias_traveler.toLowerCase()} applications or forms associated.
                    </Paragraph>
                  </div>
                </div>

                <div className={sBatchUnassignSuitcase.row}>
                  <div className={sBatchUnassignSuitcase.item}>
                    <Paragraph avenirNextMedium>Are you sure you want to continue?</Paragraph>
                  </div>
                </div>

                <div className={sBatchUnassignSuitcase.footer}>
                  <div className={sBatchUnassignSuitcase['button-content']}>
                    <Button
                      display="secondary"
                      kind="solid"
                      size="medium"
                      aria-label="Cancel Button"
                      onClick={() => props.onClose(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={sBatchUnassignSuitcase['button-content']}>
                    <Button
                      display="primary"
                      kind="solid"
                      size="medium"
                      type="submit"
                      aria-label="Unassign suitcases from term Button"
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ViaModal>
      <BatchUnassignSuitcaseWarning
        show={showWarning}
        onClose={() => {
          setShowWarning(false);
          setFailureMessage();
        }}
        suitCaseNames={props.suitCaseNames}
        selectedPrograms={props.programIds}
        submitValues={submitValues}
        programIds={props.programRangeIds}
        customAliases={props.customAliases}
        setSelectedPrograms={() => {
          props.setSelectedPrograms();
        }}
        confirm={() => {
          dispatch(unassignSuitcases(submitValues));
          props.setSelectedPrograms();
        }}
      />

      <BatchUnassignSuitcaseSuccess
        show={showSuccessModal}
        onClose={() => {
          setShowSuccessModal(false);
          setFailureMessage();
        }}
        failureMessage={failureMessage}
        suitCaseNames={suitCaseNames}
        selectedPrograms={props.programIds}
        submitValues={submitValues}
        programIds={props.programRangeIds}
        customAliases={props.customAliases}
        setSelectedPrograms={() => {
          props.setSelectedPrograms();
        }}
      />
    </>
  );
}

BatchUnassignSuitcase.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

export default enhance(BatchUnassignSuitcase);
