import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import _compact from 'lodash/compact';
import _sortBy from 'lodash/sortBy';
import _uniq from 'lodash/uniq';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './TravelerHero.css';
import { manualSyncTraveler, clearManualSyncTraveler } from '../../../actions/profileActions';
import DeleteTravelerModal from '../Modals/DeleteTravelerModal';
import ToastMessage from '../../../sites/viaGlobal/components/toastMessage';
import AddFormsTravelerProfileModal from '../../../sites/forms/pages/admin/modals/AddFormsTravelerProfile.modal';
import AddApplicationTravelerProfileModal from '../../../sites/forms/pages/admin/modals/AddApplicationTravelerProfile.modal';
import ApplicationTimingWarningModal from '../../../sites/forms/pages/admin/modals/ApplicationTimingWarning.modal.js';
import StyledIcon from '../../../styledComponents/styles/Icon.styled';
import DropdownSelect from '../../../styledComponents/styles/DropdownSelect.styled';


const TravelerHero = () => {
  const dispatch = useDispatch();
  //const [disabled, setDisabled] = useState();
  const customAliases = useSelector(state => state.profile.customAliases);
  const [showDeleteModal, setShowDeleteModal] = useState();
  const [showAddFormsModal, setShowAddFormsModal] = useState();
  const [showAddApplicationModal, setShowAddApplicationModal] = useState();
  const [showApplicationTimingModal, setShowApplicationTimingModal] = useState(false);
  const [showToastMessage, setShowToastMessage] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastFailure, setShowToastFailure] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const [selectedTerm, setSelectedTerm] = useState([]);
  const travelerDeleted = useSelector(state => state.profile.deleteTraveler);

  const { addFormsProfile, addApplicationProfile } = useSelector(state => state.forms);
  const { profile: userProfile, currentUser, header, orgTimezone, permissions, manualSync } = useSelector(
    state => state.profile,
  );


  useEffect(
    () => {
      if (!!travelerDeleted && !!travelerDeleted.data) {
        if (travelerDeleted.data.message) {
         setToastMessage(`${customAliases.alias_traveler} deleted`);
         setShowToastSuccess(true);
        } else if (travelerDeleted.data.error) {
         setToastMessage(`Unable to delete ${customAliases.alias_traveler.toLowerCase()}`);
         setShowToastFailure(true);
        }
      }else{
        if(travelerDeleted?.error){
          setToastMessage(`Unable to delete ${customAliases.alias_traveler.toLowerCase()}`);
          setShowToastFailure(true);
        }
      }
    },
    [travelerDeleted],
  );

  useEffect(
    () => {
      let msg;
      if (!addFormsProfile?.loading && addFormsProfile?.data) {
        msg = `${addFormsProfile.data.length} ${addFormsProfile.data.length === 1 ? `form` : 'forms'} added`;
        setShowToastMessage({ isError: false, message: msg });
      } else if (!addFormsProfile?.loading && addFormsProfile?.error) {
        msg = 'Error adding form';
        setShowToastMessage({ isError: true, message: msg });
      }
    },
    [addFormsProfile],
  );

  useEffect(
    () => {
      if (!addApplicationProfile?.loading && addApplicationProfile?.data?.data) {
        setShowToastMessage({ isError: false, message: 'Application added' });
      } else if (!addApplicationProfile?.loading && addApplicationProfile?.errorMessage) {
        if(addApplicationProfile?.errorMessage?.errors){
          const msg =
            addApplicationProfile?.errorMessage?.errors[0] === 'Traveler already has an application for this term'
              ? 'Application already exists'
              : 'Error adding application';
          setShowToastMessage({ isError: true, message: msg });
        }else{
          setShowApplicationTimingModal(true)
        }
      }
    },
    [addApplicationProfile],
  );

  useEffect(
    () => {
      if (manualSync?.notice === 'Sync requested') {
        setShowToastMessage({ isError: false, message: 'SIS data requested' });
      }
    },
    [manualSync],
  );

  const presentableStatus = status => {
    switch (status) {
      case 'invited':
        return 'Invited';
      case 'interested':
        return 'Interested';
      case 'applying':
        return 'Applying';
      case 'predeparture':
        return 'Predeparture';
      case 'on_site':
        return 'On-Site';
      case 'alumni':
        return 'Alumni';
      case 'deferred_withdrawn_closed':
        return 'Deferred/Withdrawn/Closed';
    }
  };

  const renderStatuses = arr => {
    let unique_array = [];
    for (let i = 0; i < arr.length; i++) {
      if (unique_array.indexOf(arr[i]) == -1) {
        unique_array.push(arr[i]);
      }
    }
    let refined_array = unique_array.map(sample => presentableStatus(sample));

    const specificOrder = [
      'Invited',
      'Interested',
      'Applying',
      'Predeparture',
      'On-Site',
      'Alumni',
      'Deferred/Withdrawn/Closed',
    ];
    return _compact(_sortBy(refined_array, obj => specificOrder.indexOf(obj)));
  };

  const travelerName = (profile, header) => {
    let firstName = profile.info.preferred_first_name;
    firstName = firstName === '' ? header.FirstName : firstName;

    let lastName = profile.info.preferred_last_name;
    lastName = lastName === '' ? header.LastName : lastName;

    let fullName = `${firstName} ${lastName}`;
    fullName = fullName.trim() === '' ? header.Email : fullName;

    return fullName;
  };

  const handleActionSelect = value => {
    if (value === '1') {
      setShowAddFormsModal(true);
    } else if (value === '2') {
      setShowAddApplicationModal(true);
    } else if (value === '3') {
      dispatch(manualSyncTraveler(userProfile[0].profile.user_id));
      //setDisabled(true);
      setTimeout(() => {
        //setDisabled(false);
        dispatch(clearManualSyncTraveler());
      }, 30000);
    } else {
      setShowDeleteModal(true);
    }
  };

  const closeToast = () => {
    setShowToastMessage(false);
  };

  const handleAddAppModalClose = (data) => {
    setSelectedTerm(data)
    setShowAddApplicationModal()
  }

  const { SsoUser: ssoUser, ChevronStatuses, Avatar, LastLogin, Email } = header?.[0] || {};
  const profile = userProfile[0];
  const birthDate = moment(profile.info.dob);
  const age = moment().diff(birthDate, 'years');
  let ageGroup = "Not Specified";
  if(age>=18) {
    ageGroup = "Adult";
  } else if (age<18) {
    ageGroup = "Minor";
  }
  const statuses = renderStatuses(ChevronStatuses).join(', ');
  const name = travelerName(profile, header?.[0] || {});
  const avatarURL = Avatar;

  const travelerId = profile.profile.user_id;
  let selectOptions = [];

  const { traveler_applications_and_forms_settings = [], traveler_information_settings = [] } = permissions || {};

  if (traveler_applications_and_forms_settings.includes('edit_traveler_forms') && travelerId !== currentUser.id) {
    selectOptions.push({ label: 'Add General Forms', value: '1' });
  }

  if (
    traveler_applications_and_forms_settings.includes('edit_traveler_applications') &&
    travelerId !== currentUser.id
  ) {
    selectOptions.push({ label: 'Add Application', value: '2' });
  }

  if (ssoUser && travelerId !== currentUser.id) {
    selectOptions.push({ label: 'Manual Data Request', value: '3' });
  }

  if (traveler_information_settings?.includes('inactivate_traveler') && travelerId !== currentUser.id) {
    selectOptions.push(...selectOptions, {
      label: `Delete Traveler`,
      value: '4',
      icon: <StyledIcon type="Trash" size="16px" color="#8B1313" />,
      backgroundColor: '#FFDEDB',
      fontColor: '#8B1313',
    });
  }

  return (
    <div className={s.firstBorder}>
      <div className={s.secondBorder}>
        <h2 className={s.hiddenName}>{name}</h2>

        <section className={s.profile}>
          <div className={s.avatar}>{Avatar && <img src={avatarURL} className={s.profileImg} alt="User avatar" />}</div>
          <p className={s.statuses}>{statuses}</p>
          <p className={s.lastSignIn}>
            Last Login:{' '}
            {LastLogin
              ? orgTimezone
                ? moment(new Date(LastLogin))
                    .tz(orgTimezone)
                    .format('MMMM D, YYYY')
                : moment(new Date(LastLogin)).format('MMMM D, YYYY')
              : '--'}
          </p>
        </section>

        <section className={s.personalInfo}>
          <span className={s.nameContainerSync}>
            <h2 className={s.travelerName}>{name}</h2>
            <span className={s.syncButton}>
              <DropdownSelect
                key="action_option_key"
                isActionList
                aria-label="Admin Actions on Traveler Profile Options Dropdown"
                placeholder="Actions"
                id="action_option_key"
                options={_uniq(selectOptions)}
                width="156px"
                onChange={e => {
                  handleActionSelect(e);
                }}
                controlShouldRenderValue={false}
              />
            </span>
          </span>

          <section />
          <div className={s.travelerInfo}>
            <div className={s.personalColumn}>
              <h3 className={s.infoHeader}>
                <span className={s.dividerLine}>Personal</span>
              </h3>

              <ul className={s.personalInfoList}>
                <li>
                  <p className={s.infoItemHeader}>Phone</p>
                  <p className={s.infoItemText}>{profile.info.phone_number}</p>
                </li>
                <li>
                  <p className={s.infoItemHeader}>Email Address</p>
                  <p className={s.infoItemText}>{Email || `N/A`}</p>
                </li>
                <li>
                  <p className={s.infoItemHeader}>Pronoun</p>
                  <p className={s.infoItemText}>{profile.info.pronoun}</p>
                </li>
                <li>
                <p className={s.infoItemHeader}>Age Group</p>
                <p
                  className={`${s.ageGroup} ${
                    ageGroup === "Adult"
                      ? s.ageGroupAdult
                      : ageGroup === "Minor"
                      ? s.ageGroupMinor
                      : s.ageGroupNotSpecified
                  }`}
                >
                  {ageGroup}
                </p>
                </li>
              </ul>
            </div>

            <div className={s.schoolColumn}>
              <h3 className={s.infoHeader}>
                <span className={s.dividerLine}>School</span>
              </h3>

              <ul className={s.personalInfoList}>
                <li>
                  <p className={s.infoItemHeader}>Home Campus</p>
                  <p className={s.infoItemText}>{profile.info.school_name}</p>
                </li>
                <li>
                  <p className={s.infoItemHeader}>Major / Minor</p>
                  <p className={s.infoItemText}>
                    {profile.info.primary_major}
                    {profile.info.primary_minor && ', '}
                    {profile.info.primary_minor}
                  </p>
                </li>
                <li>
                  <p className={s.infoItemHeader}>Student ID</p>
                  <p className={s.infoItemText}>{profile.info.student_id}</p>
                </li>
                <li>
                  <p className={s.infoItemHeader}>Grad Year</p>
                  <p className={s.infoItemText}>{profile.info.grad_year}</p>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      {showDeleteModal && (
        <DeleteTravelerModal show={showDeleteModal} travelerName={name} onClose={() => setShowDeleteModal()} />
      )}
      {showAddFormsModal && (
        <AddFormsTravelerProfileModal
          show={showAddFormsModal}
          travelerName={name}
          onClose={() => setShowAddFormsModal()}
          travelerId={travelerId}
        />
      )}
      {showAddApplicationModal && (
        <AddApplicationTravelerProfileModal
          show={showAddApplicationModal}
          travelerName={name}
          onClose={handleAddAppModalClose}
          travelerId={travelerId}
        />
      )}

     {showApplicationTimingModal && (
        <ApplicationTimingWarningModal
          show={showApplicationTimingModal}
          travelerName={name}
          selectedTerm={selectedTerm}
          onClose={() => setShowApplicationTimingModal(false)}
          travelerId={travelerId}
        />
      )}

      {!!showToastMessage && (
        <ToastMessage
          message={showToastMessage?.message}
          isSuccess={!showToastMessage?.isError}
          id="successful-update"
          show={!!showToastMessage?.message}
          onClose={() => closeToast()}
        />
      )}
      {!!showToastSuccess && (
        <ToastMessage
          message={toastMessage}
          show={showToastSuccess}
          isSuccess
          onClose={() => {
            setShowToastSuccess(false);
            setToastMessage('');
            setTimeout(() => {
              window.location.href = '/client/traveler-dashboard';
            }, 2000);
          }}
        />
       )}
      {!!showToastFailure && (
        <ToastMessage
          message={toastMessage}
          show={showToastFailure}
          isSuccess={false}
          onClose={() => {
            setShowToastFailure(false);
            setToastMessage('');
          }}
        />
      )}
    </div>
  );
};
export default withStyles(s)(TravelerHero);
