import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import InputSelect from '../../../library/inputSelect';
import { buildSelectOptions } from '../../../../../shared/helpers/General';

export const enhance = compose();

function FilterSingleSelect(props) {
  const [filterOptions, setFilterOptions] = useState(props.sourceData);
  const [defaultValues, setDefaultValues] = useState([]);

  const selectedValues = values => {
    let filterValues = [];

    filterValues.push(values.value);
    props.change(filterValues);
  };

  useEffect(() => {
    if (props.sourceData) {
      setFilterOptions(props.sourceData);
    }
    if (props.selectedValues.length > 0) {
      let obj = props.sourceData.find(o => o.value === props.selectedValues[0]);
      setDefaultValues(obj);
    }
  }, [props]);

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
    borderBottom: '1px solid #ebebeb',
  };
  const groupBadgeStyles = {
    backgroundColor: '#ebebeb',
    borderRadius: '2em',
    color: '#043544',
    display: !props.showGroupCounts ? 'none' : 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.2em 0.5em',
    textAlign: 'center',
  };

  const formatGroupLabel = data => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  return (
    <>
      {!!filterOptions && filterOptions.length > 0 && (
        <InputSelect
          id={props.id}
          labelText={props.labelText}
          styles={{
            menu: provided => ({ ...provided, zIndex: 99999 }),
          }}
          options={filterOptions}
          onChange={e => selectedValues(e)}
          formatGroupLabel={formatGroupLabel}
          isRequired={props.isRequired}
          defaultValue={props.selectedTemplate.length > 0 ? props.selectedTemplate : defaultValues}
          value={props.selectedTemplate.length > 0 ? props.selectedTemplate : defaultValues}
          placeholder={props.placeholder ? props.placeholder : ''}
        />
      )}
    </>
  );
}

FilterSingleSelect.propTypes = {
  id: propTypes.string.isRequired,
  change: propTypes.func.isRequired,
  isRequired: propTypes.bool,
  selectedValues: propTypes.array,
  showGroupCounts: propTypes.bool,
  sourceData: propTypes.array.isRequired,
  selectedTemplate: propTypes.array,
};

FilterSingleSelect.defaultProps = {
  isRequired: false,
  labelText: 'Filter Options',
  selectedValues: [],
  showGroupCounts: false,
  selectedTemplate: [],
};

export default enhance(FilterSingleSelect);
