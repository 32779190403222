import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import _get from 'lodash/get';
import { toast } from 'react-toastify';
import { AccentButtonMediumNunito, ButtonMediumNunito } from '../../../../styledComponents/styles/Buttons.styled';
import { AccentButtonSpinner } from '../../../../styledComponents/styles/spinner.styled';
import SaveFilterStyledModal from './SaveFilterStyledModal';
import TextInput from '../../../../styledComponents/styles/TextInput.styled';
import Checkbox from '../../../../styledComponents/styles/Checkbox.styled';
import { themeViaForms } from '../../../../styledComponents/settings/theme';
import toastMessageStyles from '../../../viaGlobal/components/toastMessage/toastMessage.scss';
import {
  createReportFilter,
  updateReportFilter,
  deleteReportFilter,
  fetchReportFilters,
} from '../../../../actions/reportsActions';

const CheckboxContainer = styled.div`
  margin-top: 15px;
`;

const ButtonContainer = styled.div`
  background: #ebebeb;
  display: flex;
  height: 100px;

  left: 0;
  top: 273px;
  width: 520px;
  margin-bottom: -20px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
`;

const ButtonDiv = styled.div`
  margin-top: 30px;
  margin-right: 20px;
`;
const DeleteButton = styled.button`
  color: #8b1313;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  margin-left: 20px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
  vertical-align: bottom;
  margin-top: 7px;
  letter: 6%;
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

const Main = styled.main`
  justify-content: space-between;
  display: flex;
  margin-left: 32px;
  margin-right: 70px;
  margin-top: 12px;
`;

export default function SaveFilterModal(props) {
  const dispatch = useDispatch();
  const [sharedViewCheckbox, setSharedViewCheckbox] = useState(true);
  const [updateColumState, setUpdateColumState] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isSharedChecked, setIsSharedChecked] = useState(false);
  const [isUpdateChecked, setIsUpdateChecked] = useState(true);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [updating, setUpdating] = useState(false);
  const createFilter = useSelector(state => state.reports.createReportFilter);
  const deleteFilter = useSelector(state => state.reports.deleteReportFilter);
  const updateFilter = useSelector(state => state.reports.updateReportFilter);

  const schema = yup.object().shape({
    viewName: yup.string().required('View name must have a name'),
  });

  const { control, handleSubmit, formState, getValues, register, setValue, reset } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const onSubmitHandler = data => {
    setLoadingButton(true);
    const savedColumnState = props.gridRef.current.columnApi.getColumnState();
    const savedColumnGroupState = props.gridRef.current.columnApi.getColumnGroupState();
    const savedFilterState = props.gridRef.current.api.getFilterModel();
    const isPivotMode = props.gridRef.current.columnApi.isPivotMode();
    const savedQuickFilter = props.gridRef.current.api.filterManager.quickFilter;
    const templateId = props.selectedTemplateId ? props.selectedTemplateId : null;
    const orgId = props.selectedOrgId ? props.selectedOrgId : null;

    const gridStateSave = {
      savedColumnState,
      savedColumnGroupState,
      savedFilterState,
      isPivotMode,
      savedQuickFilter,
      templateId,
      orgId,
    };

    let setFilter = {
      filter: JSON.stringify(gridStateSave),
      filter_type: props.filterKey,
      name: data?.viewName,
      shared: sharedViewCheckbox,
    };

    if (props.filterID) {
      if (updateColumState) {
        setFilter = {
          name: data?.viewName,
          shared: sharedViewCheckbox,
        };
      }
      setUpdating(true);
      dispatch(updateReportFilter(props.filterID, setFilter));
    } else {
      setSaving(true);
      dispatch(createReportFilter(setFilter));
    }
  };

  useEffect(() => {
    if (saving && !createFilter?.loading) {
      setSaving(false);

      if (createFilter?.error) {
        toast(createFilter?.error, {
          type: 'error',
          className: toastMessageStyles['failureContainer'],
          toastClassName: toastMessageStyles['wrapper'],
          bodyClassName: toastMessageStyles['failureBody'],
          progressClassName: toastMessageStyles['progress-bar'],
          position: 'bottom-right',
        });
      } else {
        toast('View Created', {
          type: 'success',
          className: toastMessageStyles['successContainer'],
          toastClassName: toastMessageStyles['wrapper'],
          bodyClassName: toastMessageStyles['successBody'],
          progressClassName: toastMessageStyles['progress-bar'],
          position: 'bottom-right',
        });
      }
      dispatch(fetchReportFilters(props.filterKey));

      setTimeout(() => {
        const returnViewValues = {
          value: createFilter?.data?.id.toString(),
          label: createFilter?.data?.attributes?.name,
          shared: createFilter?.data?.attributes?.shared.toString(),
        };
        props.handlesubmit(returnViewValues);
        setLoadingButton(false);
      }, 500);
    }
  }, [createFilter]);

  useEffect(() => {
    if (updating && !updateFilter?.loading) {
      setUpdating(false);

      if (updateFilter?.error) {
        toast(updateFilter?.error, {
          type: 'error',
          className: toastMessageStyles['failureContainer'],
          toastClassName: toastMessageStyles['wrapper'],
          bodyClassName: toastMessageStyles['failureBody'],
          progressClassName: toastMessageStyles['progress-bar'],
          position: 'bottom-right',
        });
      } else {
        toast('View Saved', {
          type: 'success',
          className: toastMessageStyles['successContainer'],
          toastClassName: toastMessageStyles['wrapper'],
          bodyClassName: toastMessageStyles['successBody'],
          progressClassName: toastMessageStyles['progress-bar'],
          position: 'bottom-right',
        });
      }
      dispatch(fetchReportFilters(props.filterKey));

      setTimeout(() => {
        const returnViewValues = {
          value: updateFilter?.data?.data?.id.toString(),
          label: updateFilter?.data?.data?.attributes?.name,
          shared: updateFilter?.data?.data?.attributes?.shared.toString(),
        };
        props.handlesubmit(returnViewValues);
        setLoadingButton(false);
      }, 500);
    }
  }, [updateFilter]);

  useEffect(() => {
    if (deleting && !deleteFilter?.loading) {
      setDeleting(false);
      if (deleteFilter?.error) {
        toast(deleteFilter?.error, {
          type: 'error',
          className: toastMessageStyles['failureContainer'],
          toastClassName: toastMessageStyles['wrapper'],
          bodyClassName: toastMessageStyles['failureBody'],
          progressClassName: toastMessageStyles['progress-bar'],
          position: 'bottom-right',
        });
      } else {
        toast('View Deleted', {
          type: 'success',
          className: toastMessageStyles['successContainer'],
          toastClassName: toastMessageStyles['wrapper'],
          bodyClassName: toastMessageStyles['successBody'],
          progressClassName: toastMessageStyles['progress-bar'],
          position: 'bottom-right',
        });
      }
      dispatch(fetchReportFilters(props.filterKey));

      setTimeout(() => {
        props.handlesubmit(null);
      }, 500);
    }
  }, [deleteFilter]);

  const deleteView = filterID => {
    setDeleting(true);
    dispatch(deleteReportFilter(filterID));
  };

  useEffect(() => {
    if (props.filterID) {
      setValue('viewName', props.filterAttributes.label);
      setIsSharedChecked(props.filterAttributes.shared == 'true' ? true : false);
    }
  }, [props.filterID]);

  return (
    <SaveFilterStyledModal
      header={props.filterID ? 'Edit Saved View' : 'Create Saved View'}
      showing={props.showing}
      width="520px"
      onClose={props.onClose}
    >
      <ThemeProvider theme={themeViaForms}>
        <form id="save-view" onSubmit={handleSubmit(onSubmitHandler)}>
          <FlexContainer>
            <Main>
              <div>
                <Controller
                  control={control}
                  name={'viewName'}
                  render={({ field: { onChange, defaultValue, value, name, ref } }) => (
                    <TextInput
                      errorMsg={_get(formState.errors.viewName, 'message') || ''}
                      id={'viewName'}
                      label={props.filterID ? 'Rename view' : 'View Name'}
                      required
                      name={'viewName'}
                      placeholder={'Enter View name'}
                      control={control}
                      errors={formState.errors}
                      isDirty={formState.isDirty}
                      value={value}
                      getValues={getValues}
                      register={register}
                      onChange={value => {
                        setValue('viewName', value, {
                          shouldValidate: true,
                          shouldDirty: true,
                          shouldTouch: true,
                        });
                        onChange(value);
                      }}
                    />
                  )}
                />
                <CheckboxContainer>
                  <Checkbox
                    id={'shareView'}
                    onChange={e => setSharedViewCheckbox(!sharedViewCheckbox)}
                    name={'shareView'}
                    defaultChecked={isSharedChecked}
                    label={<span style={{ fontWeight: '600' }}>Share view with my organization</span>}
                  />
                  {props.filterID && (
                    <Checkbox
                      id={'updateView'}
                      onChange={e => setUpdateColumState(!updateColumState)}
                      name={'updateView'}
                      defaultChecked={isUpdateChecked}
                      label={
                        <span style={{ fontWeight: '600' }}>Update saved view with selected columns and filters</span>
                      }
                    />
                  )}
                </CheckboxContainer>
              </div>
            </Main>
          </FlexContainer>
          <ButtonContainer style={{ justifyContent: props.filterID ? 'space-between' : 'flex-end' }}>
            {props.filterID && (
              <DeleteButton type="button" onClick={() => deleteView(props.filterID)}>
                Delete View
              </DeleteButton>
            )}
            <ButtonDiv>
              <ButtonMediumNunito
                style={{ backgroundColor: '#EEEEEE', marginRight: '20px' }}
                aria-label="Close Modal"
                type="button"
                onClick={() => props.onClose(false)}
              >
                Cancel
              </ButtonMediumNunito>
              <AccentButtonMediumNunito type="submit" form="save-view">
                {loadingButton ? <AccentButtonSpinner displayText="Saving ..." /> : 'Save'}
              </AccentButtonMediumNunito>
            </ButtonDiv>
          </ButtonContainer>
        </form>
      </ThemeProvider>
    </SaveFilterStyledModal>
  );
}

SaveFilterModal.defaultProps = {
  showing: false,
};
