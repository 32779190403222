exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2jx-H{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;gap:5px;-ms-flex-align:center;align-items:center}.Zf2wJ{font-family:Open Sans;font-size:14px;font-weight:400;line-height:16.8px;letter-spacing:.0025em}._2Fd0b{height:25px}", ""]);

// exports
exports.locals = {
	"container": "_2jx-H",
	"label": "Zf2wJ",
	"savedImg": "_2Fd0b"
};