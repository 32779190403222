import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { fetchProviderClientAccounts } from '../../../../../actions/lookupActions';
import FilterSingleSelect from '../../filterTypes/filterSingleSelect';

export const enhance = compose();

function ProgramProviderClientAccountsFilter(props) {
  const dispatch = useDispatch();
  const providerClientAccounts = useSelector(state => state.lookup.allProviderClientAccounts);
  const [sourceOptions, setSourceOptions] = useState([]);
  const [placeholder, setPlaceholder] = useState();
  const [clientSelected, setClientSelected] = useState(['select']);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      if (!providerClientAccounts.providerClientAccounts) {
        dispatch(fetchProviderClientAccounts());
      } else {
        let options = [];

        options.push({
          label: 'Select Organization',
          value: 'select',
        });

        providerClientAccounts.providerClientAccounts.map(item => {
          if (item.attributes.org_name.length > 0) {
            options.push({
              label: item.attributes.org_name,
              value: item.id,
            });
          }
        });

        setSourceOptions(options);
      }
    }
    return () => {
      unmounted = true;
    };
  }, [providerClientAccounts]);

  const selectedValues = values => {
    setPlaceholder(sourceOptions.filter(option => option.value === values[0])[0].label);
    const returnValues = values[0] === 'select' ? [] : values;
    props.change(returnValues);
  };

  useEffect(() => {
    if (props.selectedValues?.length > 0) {
      setClientSelected(typeof props.selectedValues === 'string' ? [props.selectedValues] : props.selectedValues);
    } else {
      setClientSelected(['select']);
    }
  }, [props.selectedValues]);

  return (
    <FilterSingleSelect
      id={props.id}
      sourceData={sourceOptions}
      selectedValues={clientSelected}
      change={e => selectedValues(e)}
      labelText={props.displayText}
      isRequired
      placeholder={!!placeholder ? placeholder : ''}
    />
  );
}

ProgramProviderClientAccountsFilter.propTypes = {
  change: propTypes.func.isRequired,
  displayText: propTypes.string,
  id: propTypes.string.isRequired,
  selectedValues: propTypes.array,
};

ProgramProviderClientAccountsFilter.defaultProps = {
  displayText: 'Organization Name',
  selectedValues: [],
};

export default enhance(ProgramProviderClientAccountsFilter);
