exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _26z9P{to{-webkit-transform:rotate(1turn)}}@keyframes _26z9P{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._1OTSP{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}._1OTSP ._2DJsh{padding:24px 40px}._1OTSP ._2DJsh p{padding-bottom:24px}._1OTSP .sWnjc{margin-bottom:52px}._1OTSP ._1AGtS{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;padding:12px 20px}@media only screen and (max-width:599px){._1OTSP ._1AGtS{-ms-flex-direction:column;flex-direction:column}}._1OTSP ._1AGtS ._3bh_5{-ms-flex:1 1;flex:1 1;padding:12px 20px}._1OTSP ._1AGtS ._3bh_5 ._3KWn7{cursor:pointer;padding-left:12px;font-size:16px}._1OTSP ._1NQ9T{-ms-flex-direction:column;flex-direction:column;display:-ms-flexbox;display:flex;padding:12px 40px 24px}._1OTSP ._1NQ9T ._aRh7{-ms-flex:1 1;flex:1 1}._1OTSP ._1NQ9T ._aRh7 .jFMoU{cursor:pointer;padding-bottom:24px;color:#767676;font-family:AvenirNextRegular,sans-serif;font-weight:500;font-size:16px;line-height:140%}._1OTSP ._2ryPx{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;border-bottom:2px solid #ebebeb}@media only screen and (max-width:599px){._1OTSP ._2ryPx{-ms-flex-direction:column;flex-direction:column}}._1OTSP ._2ryPx ._3bh_5{-ms-flex:1 1;flex:1 1;margin:0 20px;padding-left:20px;padding-bottom:12px;padding-top:12px}._1OTSP ._2ryPx ._3bh_5 ._3KWn7{cursor:pointer;padding-left:12px;font-size:16px}._1OTSP ._1GGgy{-ms-flex:1 1;flex:1 1;-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;border-top:2px solid #ebebeb;padding:12px 40px;-ms-flex-pack:end;justify-content:flex-end}@media only screen and (max-width:599px){._1OTSP ._1GGgy{-ms-flex-direction:column;flex-direction:column}}._1OTSP ._1GGgy ._2GKwF{padding-left:12px}@media only screen and (max-width:599px){._1OTSP ._1GGgy ._2GKwF{padding-top:12px}}", ""]);

// exports
exports.locals = {
	"batch-add-forms-modal": "_1OTSP",
	"content": "_2DJsh",
	"form-block": "sWnjc",
	"row": "_1AGtS",
	"item": "_3bh_5",
	"trash": "_3KWn7",
	"formBlock": "_1NQ9T",
	"formBlockContent": "_aRh7",
	"addForm": "jFMoU",
	"underlinedRow": "_2ryPx",
	"footer": "_1GGgy",
	"button-content": "_2GKwF",
	"spin": "_26z9P"
};