import fetch from 'node-fetch';
import token from '../shared/helpers/token';
import { keys } from '../config/keys';
import { getTravelerApplicationSubmissions } from '../sites/travelerProfile/actions/travelerApplicationsActions';
import {
  FORMS_LIST_CLEAR,
  FORMS_LIST_FAILURE,
  FORMS_LIST_REQUEST,
  FORMS_LIST_SUCCESS,
  CREATE_FORM_REQUEST,
  CREATE_FORM_SUCCESS,
  CREATE_FORM_CLEAR,
  CREATE_FORM_FAILURE,
  FETCH_QUESTION_TYPES_CLEAR,
  FETCH_QUESTION_TYPES_REQUEST,
  FETCH_QUESTION_TYPES_FAILURE,
  FETCH_QUESTION_TYPES_SUCCESS,
  FETCH_FORM_CLEAR,
  FETCH_FORM_SUCCESS,
  FETCH_FORM_FAILURE,
  FAKE_FORM_UPDATE,
  UPDATE_FORM_ON_CLIENT,
  UPDATE_FORM_CLEAR,
  UPDATE_FORM_REQUEST,
  UPDATE_FORM_SUCCESS,
  UPDATE_FORM_FAILURE,
  UPDATE_FORM_STATUS_CLEAR,
  UPDATE_FORM_STATUS_REQUEST,
  UPDATE_FORM_STATUS_SUCCESS,
  UPDATE_FORM_STATUS_FAILURE,
  UPDATE_BUILDER_FORM_STATUS_SUCCESS,
  UPDATE_QUESTION_CLEAR,
  UPDATE_QUESTION_REQUEST,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_FAILURE,
  DUPLICATE_FORM_CLEAR,
  DUPLICATE_FORM_REQUEST,
  DUPLICATE_FORM_SUCCESS,
  DUPLICATE_FORM_FAILURE,
  DELETE_FORM_CLEAR,
  DELETE_FORM_REQUEST,
  DELETE_FORM_SUCCESS,
  DELETE_FORM_FAILURE,
  UPDATE_LAYOUT_CLEAR,
  UPDATE_LAYOUT_REQUEST,
  UPDATE_LAYOUT_SUCCESS,
  UPDATE_LAYOUT_FAILURE,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAILURE,
  UPDATE_FORM_NAME_CLEAR,
  UPDATE_FORM_NAME_REQUEST,
  UPDATE_FORM_NAME_SUCCESS,
  UPDATE_FORM_NAME_FAILURE,
  FETCH_TRAVELER_SUBMISSION_REQUEST,
  FETCH_TRAVELER_SUBMISSION_SUCCESS,
  FETCH_TRAVELER_SUBMISSION_FAILURE,
  FETCH_TRAVELER_SUBMISSION_CLEAR,
  PATCH_TRAVELER_FORM_REQUEST,
  PATCH_TRAVELER_FORM_SUCCESS,
  PATCH_TRAVELER_FORM_FAILURE,
  PATCH_TRAVELER_FORM_CLEAR,
  FETCH_SUBMISSION_STATUSES_REQUEST,
  FETCH_SUBMISSION_STATUSES_SUCCESS,
  FETCH_SUBMISSION_STATUSES_FAILURE,
  FETCH_SUBMISSION_STATUSES_CLEAR,
  FETCH_VISITOR_SUBMISSION_REQUEST,
  FETCH_VISITOR_SUBMISSION_SUCCESS,
  FETCH_VISITOR_SUBMISSION_FAILURE,
  FETCH_VISITOR_SUBMISSION_CLEAR,
  FETCH_TRAVELER_ALTERNATE_INFO_REQUEST,
  FETCH_TRAVELER_ALTERNATE_INFO_SUCCESS,
  FETCH_TRAVELER_ALTERNATE_INFO_FAILURE,
  FETCH_TRAVELER_ALTERNATE_INFO_CLEAR,
  POST_SEND_RECOMMENDATION_MAILER_REQUEST,
  POST_SEND_RECOMMENDATION_MAILER_SUCCESS,
  POST_SEND_RECOMMENDATION_MAILER_FAILURE,
  POST_SEND_RECOMMENDATION_MAILER_CLEAR,
  CANCEL_SEND_RECOMMENDATION_MAILER_REQUEST,
  CANCEL_SEND_RECOMMENDATION_MAILER_SUCCESS,
  CANCEL_SEND_RECOMMENDATION_MAILER_FAILURE,
  CANCEL_SEND_RECOMMENDATION_MAILER_CLEAR,
  RESEND_RECOMMENDATION_MAILER_REQUEST,
  RESEND_RECOMMENDATION_MAILER_SUCCESS,
  RESEND_RECOMMENDATION_MAILER_FAILURE,
  RESEND_RECOMMENDATION_MAILER_CLEAR,
  PATCH_VISITOR_SUBMISSION_REQUEST,
  PATCH_VISITOR_SUBMISSION_SUCCESS,
  PATCH_VISITOR_SUBMISSION_FAILURE,
  PATCH_VISITOR_SUBMISSION_CLEAR,
  UPDATE_FORM_SUBMISSION_RESPONSE,
  UPLOAD_FILE_ADD_REQUEST,
  UPLOAD_FILE_ADD_SUCCESS,
  UPLOAD_FILE_ADD_FAILURE,
  UPLOAD_FILE_ADD_CLEAR,
  UPLOAD_FILE_DELETE_REQUEST,
  UPLOAD_FILE_DELETE_SUCCESS,
  UPLOAD_FILE_DELETE_FAILURE,
  UPLOAD_FILE_DELETE_CLEAR,
  PAYMENT_STATUS_OPTIONS_REQUEST,
  PAYMENT_STATUS_OPTIONS_SUCCESS,
  PAYMENT_STATUS_OPTIONS_FAILURE,
  PAYMENT_STATUS_OPTIONS_CLEAR,
  PAYMENT_STATUS_FLYWIRE_UPDATE_ADMIN,
  PAYMENT_STATUS_FLYWIRE_UPDATE,
  UPDATE_ALL_PAYMENTS_STATUS_ADMIN,
  FETCH_TRAVELER_INFO_FIELDS_REQUEST,
  FETCH_TRAVELER_INFO_SUCCESS,
  FETCH_TRAVELER_INFO_FIELDS_FAILURE,
  FETCH_TRAVELER_INFO_FIELDS_CLEAR,
  SET_TRANSFER_APPLICATION_ERROR,
  SET_TRANSFER_APPLICATION_SUCCESS,
  ADMIN_ADD_FORMS_REQUEST,
  ADMIN_ADD_FORMS_SUCCESS,
  ADMIN_ADD_FORMS_FAILURE,
  ADMIN_ADD_FORMS_CLEAR,
  DELETE_TRAVELER_APPLICATION_CLEAR,
  DELETE_TRAVELER_APPLICATION_REQUEST,
  DELETE_TRAVELER_APPLICATION_FAILURE,
  DELETE_TRAVELER_APPLICATION_SUCCESS,
  ADD_FORMS_TO_TRAVELER_PROFILE_CLEAR,
  ADD_FORMS_TO_TRAVELER_PROFILE_REQUEST,
  ADD_FORMS_TO_TRAVELER_PROFILE_FAILURE,
  ADD_FORMS_TO_TRAVELER_PROFILE_SUCCESS,
  ADD_APPLICATION_TO_TRAVELER_PROFILE_CLEAR,
  ADD_APPLICATION_TO_TRAVELER_PROFILE_REQUEST,
  ADD_APPLICATION_TO_TRAVELER_PROFILE_FAILURE,
  ADD_APPLICATION_TO_TRAVELER_PROFILE_SUCCESS,
  GET_AVAILABLE_FORMS_CLEAR,
  GET_AVAILABLE_FORMS_REQUEST,
  GET_AVAILABLE_FORMS_FAILURE,
  GET_AVAILABLE_FORMS_SUCCESS,
  FETCH_AVAILABLE_APPLICATIONS_REQUEST,
  FETCH_AVAILABLE_APPLICATIONS_CLEAR,
  FETCH_AVAILABLE_APPLICATIONS_FAILURE,
  FETCH_AVAILABLE_APPLICATIONS_SUCCESS,
  FETCH_AVAILABLE_ALTERNATES_REQUEST,
  FETCH_AVAILABLE_ALTERNATES_CLEAR,
  FETCH_AVAILABLE_ALTERNATES_FAILURE,
  FETCH_AVAILABLE_ALTERNATES_SUCCESS,
  SET_ALTERNATE_SAVE_ERROR,
  REQUEST_TO_WITHDRAW_REQUEST,
  REQUEST_TO_WITHDRAW_SUCCESS,
  REQUEST_TO_WITHDRAW_FAILURE,
  RESPOND_REQUEST_TO_WITHDRAW_REQUEST,
  RESPOND_REQUEST_TO_WITHDRAW_SUCCESS,
  RESPOND_REQUEST_TO_WITHDRAW_FAILURE,
  FETCH_AVAILABLE_TAG_REQUEST,
  FETCH_AVAILABLE_TAG_SUCCESS,
  FETCH_AVAILABLE_TAG_FAILURE,
  FETCH_AVAILABLE_TAG_CLEAR,
  FETCH_TAG_COLORS_REQUEST,
  FETCH_TAG_COLORS_SUCCESS,
  FETCH_TAG_COLORS_FAILURE,
  FETCH_TAG_COLORS_CLEAR,
  ATTACH_TAG_REQUEST,
  ATTACH_TAG_SUCCESS,
  ATTACH_TAG_FAILURE,
  ATTACH_TAG_CLEAR,
  CREATE_SUBMISSION_TAG_REQUEST,
  CREATE_SUBMISSION_TAG_SUCCESS,
  CREATE_SUBMISSION_TAG_FAILURE,
  CREATE_SUBMISSION_TAG_CLEAR,
  UPDATE_SUBMISSION_TAG_REQUEST,
  UPDATE_SUBMISSION_TAG_SUCCESS,
  UPDATE_SUBMISSION_TAG_FAILURE,
  UPDATE_SUBMISSION_TAG_CLEAR,
  DELETE_SUBMISSION_TAG_REQUEST,
  DELETE_SUBMISSION_TAG_SUCCESS,
  DELETE_SUBMISSION_TAG_FAILURE,
  GET_TOKENS_REQUEST,
  GET_TOKENS_SUCCESS,
  GET_TOKENS_FAILURE,
  BATCH_ZIP_DOWNLOAD_REQUEST,
  BATCH_ZIP_DOWNLOAD_SUCCESS,
  BATCH_ZIP_DOWNLOAD_FAILURE,
  FETCH_WITHDRAW_DEFFERED_REASON,
} from './types';

export const getForms = (type = 'General Form', status = 1) => dispatch => {
  /*
  template_type options: "General Form", "Application", "Recommendation"
  status options: 0 => ”draft”, 1 => ”published”, 2 => “unpublished”, 3 => “archived”
  */

  dispatch({ type: FORMS_LIST_REQUEST });
  fetch(`${keys.baseUri}/api/form_templates?template_type=${type}&status=${status}&dash=true`, {
    method: 'GET',
    headers: token,
  })
    .then(res => {
      if (res.status === 200) {
        res.json().then(data => {
          data.template_type = type;
          dispatch({
            type: FORMS_LIST_SUCCESS,
            payload: data,
          });
        });
      } else {
        res.json().then(message => {
          dispatch({
            type: FORMS_LIST_FAILURE,
            payload: message,
          });
        });
      }

      dispatch({
        type: FORMS_LIST_CLEAR,
      });
    })
    .catch(error => {
      dispatch({
        type: FORMS_LIST_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: FORMS_LIST_CLEAR,
      });
    });
};

export const createForm = (type = 'General Form', name = 1) => dispatch => {
  dispatch({ type: CREATE_FORM_REQUEST });
  fetch(`${keys.baseUri}/api/form_templates?template_type=${type}&name=${name}`, {
    method: 'POST',
    headers: token,
  })
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        res.json().then(data => {
          data.template_type = type;
          dispatch({
            type: CREATE_FORM_SUCCESS,
            payload: data,
          });
        });
      } else {
        res.json().then(() => {
          dispatch({
            type: CREATE_FORM_FAILURE,
            payload: 'Form name must be unique',
          });
        });
      }

      dispatch({
        type: CREATE_FORM_CLEAR,
      });
    })
    .catch(error => {
      dispatch({
        type: CREATE_FORM_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: CREATE_FORM_CLEAR,
      });
    });
};

export const getQuestionTypes = () => dispatch => {
  dispatch({ type: FETCH_QUESTION_TYPES_REQUEST });
  fetch(`${keys.baseUri}/api/question_types`, {
    method: 'GET',
    headers: token,
  })
    .then(res => {
      if (res.status === 200) {
        res.json().then(data => {
          dispatch({
            type: FETCH_QUESTION_TYPES_SUCCESS,
            payload: data,
          });
        });
      } else {
        res.json().then(message => {
          dispatch({
            type: FETCH_QUESTION_TYPES_FAILURE,
            payload: message,
          });
        });
      }

      dispatch({
        type: FETCH_QUESTION_TYPES_CLEAR,
      });
    })
    .catch(error => {
      dispatch({
        type: FETCH_QUESTION_TYPES_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: FETCH_QUESTION_TYPES_CLEAR,
      });
    });
};

export const getSubmissionStatuses = type => dispatch => {
  dispatch({ type: FETCH_SUBMISSION_STATUSES_REQUEST });
  fetch(`${keys.baseUri}/api/submission_statuses?type=${type}`, {
    method: 'GET',
    headers: token,
  })
    .then(res => {
      if (res.status === 200) {
        res.json().then(data => {
          dispatch({
            type: FETCH_SUBMISSION_STATUSES_SUCCESS,
            payload: data,
          });
        });
      } else {
        res.json().then(message => {
          dispatch({
            type: FETCH_SUBMISSION_STATUSES_FAILURE,
            payload: message,
          });
        });
      }
    })
    .catch(error => {
      dispatch({
        type: FETCH_SUBMISSION_STATUSES_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: FETCH_SUBMISSION_STATUSES_CLEAR,
      });
    });
};

export const clearFormFromState = () => dispatch => {
  dispatch({ type: FETCH_FORM_CLEAR });
};

export const fetchForm = id => async dispatch => {
  await fetch(`${keys.baseUri}/api/form_templates/${id}`, {
    method: 'GET',
    headers: token,
  })
    .then(res => {
      if (res.status === 200) {
        res.json().then(data => {
          dispatch({
            type: FETCH_FORM_SUCCESS,
            payload: data,
          });
        });
      } else {
        res.json().then(message => {
          dispatch({
            type: FETCH_FORM_FAILURE,
            payload: message,
          });
        });
      }
    })
    .catch(error => {
      dispatch({
        type: FETCH_FORM_FAILURE,
        payload: error.toString(),
      });
    });
};

export const updateForm = (formId, questionType, questionTypeId, values, travelerValues = null) => dispatch => {
  dispatch({ type: UPDATE_FORM_REQUEST });
  fetch(`${keys.baseUri}/api/form_templates/${formId}/${questionType}`, {
    method: 'POST',
    headers: token,
    body: JSON.stringify({
      question: values,
      question_type_id: questionTypeId,
      questions: travelerValues,
    }),
  })
    .then(res => res.json())
    .then(data => {
      dispatch(fetchForm(formId));

      dispatch({
        type: UPDATE_FORM_SUCCESS,
        payload: data,
      });
      dispatch({
        type: UPDATE_FORM_CLEAR,
      });
    })
    .catch(error => {
      dispatch({
        type: UPDATE_FORM_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: UPDATE_FORM_CLEAR,
      });
    });
};

export const updateFormName = (formId, value) => dispatch => {
  const data = { name: value };
  dispatch({ type: UPDATE_FORM_NAME_REQUEST });
  fetch(`${keys.baseUri}/api/form_templates/${formId}`, {
    method: 'PATCH',
    headers: token,
    body: JSON.stringify(data),
  }).then(response => {
    if (response.status === 200) {
      response.json().then(data => {
        dispatch({
          type: UPDATE_FORM_NAME_SUCCESS,
          payload: data,
        });
        dispatch({
          type: UPDATE_FORM_NAME_CLEAR,
        });
      });
    } else {
      response.json().then(() => {
        dispatch({
          type: UPDATE_FORM_NAME_FAILURE,
          message: 'Form name must be unique',
        });
        dispatch({
          type: UPDATE_FORM_NAME_CLEAR,
        });
      });
    }
  });
};

export const updateFormStatus = (value, useAlternateReducer = false) => async dispatch => {
  dispatch({ type: UPDATE_FORM_STATUS_REQUEST });
  fetch(`${keys.baseUri}/api/template_batch_actions`, {
    method: 'PATCH',
    headers: token,
    body: JSON.stringify(value),
  })
    .then(res => res.json())
    .then(data => {
      if (data.message !== '0 Form States Updated') {
        dispatch({
          type: useAlternateReducer ? UPDATE_BUILDER_FORM_STATUS_SUCCESS : UPDATE_FORM_STATUS_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: FAKE_FORM_UPDATE,
          payload: data,
        });
      }

      dispatch({
        type: UPDATE_FORM_STATUS_CLEAR,
      });
    })
    .catch(error => {
      dispatch({
        type: UPDATE_FORM_STATUS_FAILURE,
        payload: error.toString(),
      });
      // dispatch({
      //   type: UPDATE_FORM_STATUS_CLEAR,
      // });
    });
};

export const updateQuestion = (formId, questionType, questionId, value) => dispatch => {
  dispatch({ type: UPDATE_QUESTION_REQUEST });
  fetch(`${keys.baseUri}/api/form_templates/${formId}/${questionType}/${questionId}`, {
    method: 'PATCH',
    headers: token,
    body: JSON.stringify({
      question: value,
    }),
  })
    .then(res => res.json())
    .then(data => {
      dispatch(fetchForm(formId));

      dispatch({
        type: UPDATE_QUESTION_SUCCESS,
        payload: data,
      });

      dispatch({
        type: UPDATE_QUESTION_CLEAR,
      });
    })
    .catch(error => {
      dispatch({
        type: UPDATE_QUESTION_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: UPDATE_QUESTION_CLEAR,
      });
    });
};

export const duplicateForm = formId => dispatch => {
  dispatch({ type: DUPLICATE_FORM_REQUEST });
  fetch(`${keys.baseUri}/api/form_templates/duplicate`, {
    method: 'POST',
    headers: token,
    body: JSON.stringify({ id: formId }),
  })
    .then(response => {
      if (response.status === 201) {
        response.json().then(data => {
          dispatch({
            type: DUPLICATE_FORM_SUCCESS,
            payload: data,
            message: 'Form duplication successful',
          });

          dispatch({
            type: DUPLICATE_FORM_CLEAR,
          });
        });
      } else {
        dispatch({
          type: DUPLICATE_FORM_FAILURE,
          payload: null,
          message: 'Error duplicating form',
        });
        dispatch({
          type: DUPLICATE_FORM_CLEAR,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: DUPLICATE_FORM_FAILURE,
        message: 'Error duplicating form',
      });
      dispatch({
        type: DUPLICATE_FORM_CLEAR,
      });
    });
};

export const deleteForm = formId => dispatch => {
  dispatch({ type: DELETE_FORM_REQUEST });
  fetch(`${keys.baseUri}/api/form_templates/${formId}`, {
    method: 'DELETE',
    headers: token,
  })
    .then(response => {
      if (response.status === 204) {
        dispatch({
          type: DELETE_FORM_SUCCESS,
          message: 'Form delete successful',
        });

        dispatch({
          type: DELETE_FORM_CLEAR,
        });

        // Form modal to update status has a hook listening for status updates
        // to refresh data, just triggering a status clear to use that hook.
        dispatch({
          type: UPDATE_FORM_STATUS_CLEAR,
        });
      } else {
        dispatch({
          type: DELETE_FORM_FAILURE,
          message: 'Error deleting form',
        });
        dispatch({
          type: DELETE_FORM_CLEAR,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: DELETE_FORM_FAILURE,
        message: 'Error deleting form',
      });
      dispatch({
        type: DELETE_FORM_CLEAR,
      });
    });
};

export const updateLayout = values => dispatch => {
  const { id } = values;
  const saveData = JSON.stringify({
    template_layout: {
      layout: values.layout,
    },
  });

  dispatch({ type: UPDATE_LAYOUT_REQUEST });
  fetch(`${keys.baseUri}/api/template_layouts/${id}`, {
    method: 'PATCH',
    headers: token,
    body: saveData,
  })
    .then(response => {
      if (response.status === 200) {
        response.json().then(data => {
          dispatch({
            type: UPDATE_LAYOUT_SUCCESS,
            payload: data,
            message: 'Form layout update successful',
          });

          dispatch({
            type: UPDATE_LAYOUT_CLEAR,
          });
        });
      } else {
        dispatch({
          type: UPDATE_LAYOUT_FAILURE,
          message: 'Error updating layout',
        });
        dispatch({
          type: UPDATE_LAYOUT_CLEAR,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: UPDATE_LAYOUT_FAILURE,
        message: 'Error updating layout',
      });
      dispatch({
        type: UPDATE_LAYOUT_CLEAR,
      });
    });
};

export const deleteQuestion = (formId, questionType, questionId) => async (dispatch, getState) => {
  const { forms } = getState();
  let { fetchForm: formData } = forms;

  await fetch(`${keys.baseUri}/api/form_templates/${formId}/${questionType}/${questionId}`, {
    method: 'DELETE',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      //get a list of the question ids in the form
      const remainingQuestionIds = data.template_layout.layout.flatMap(x => x.id);
      //remove the recently deleted question info
      let filteredQuestions = formData.data.questions.filter(q => remainingQuestionIds.includes(String(q.question_id)));
      //remove child question logic from parent question if logic deleted
      filteredQuestions = filteredQuestions.map(q => {
        if (
          q.question_type_identifier === 'single_choice' &&
          q.single_choice_question_logic?.child_question?.id === questionId
        ) {
          q.single_choice_question_logic = null;
        } else if (
          q.question_type_identifier === 'dropdown' &&
          q.dropdown_question_logic?.child_question?.id === questionId
        ) {
          q.dropdown_question_logic = null;
        }
        return q;
      });

      const filteredLayouts = formData.data.renderedLayout.filter(q =>
        remainingQuestionIds.includes(String(q.questionId)),
      );
      //update the form data (to match reducer dump after buildForm reformat)
      formData.data.layout.layout = data.template_layout.layout;
      formData.data.questions = filteredQuestions;
      formData.data.renderedLayout = filteredLayouts;

      //update the form data in state
      dispatch({
        type: UPDATE_FORM_ON_CLIENT,
        payload: formData.data,
      });

      dispatch({
        type: DELETE_QUESTION_SUCCESS,
        payload: data,
      });
    })
    .catch(error => {
      dispatch({
        type: DELETE_QUESTION_FAILURE,
        payload: error.toString(),
      });
    });
};

export const fetchTravelerSubmission = (submissionId, clearSave = false, saveData = null) => async dispatch => {
  //This will capture the top-level submissionId for the back link if the view a form
  clearSubmission();

  dispatch({ type: FETCH_TRAVELER_SUBMISSION_REQUEST });
  await fetch(`${keys.baseUri}/api/application_submissions/${submissionId}`, {
    method: 'GET',
    headers: token,
  })
    .then(res => {
      if (res.status === 200) {
        res.json().then(data => {
          dispatch({
            type: FETCH_TRAVELER_SUBMISSION_SUCCESS,
            payload: data,
          });

          setTimeout(() => {
            if (clearSave) {
              dispatch({
                type: PATCH_TRAVELER_FORM_SUCCESS,
                payload: saveData,
                message: 'Form update successful',
              });

              dispatch({
                type: PATCH_TRAVELER_FORM_CLEAR,
              });
            }
          }, 500);
        });
      } else {
        res.json().then(message => {
          dispatch({
            type: FETCH_TRAVELER_SUBMISSION_FAILURE,
            payload: message,
          });
          setTimeout(() => {
            dispatch({
              type: FETCH_TRAVELER_SUBMISSION_CLEAR,
            });

            setTimeout(() => {
              if (clearSave) {
                dispatch({
                  type: PATCH_TRAVELER_FORM_SUCCESS,
                  payload: saveData,
                  message: 'Form update successful',
                });

                dispatch({
                  type: PATCH_TRAVELER_FORM_CLEAR,
                });
              }
            }, 500);
          }, 250);
        });
      }
    })
    .catch(error => {
      dispatch({
        type: FETCH_TRAVELER_SUBMISSION_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: FETCH_TRAVELER_SUBMISSION_CLEAR,
      });
    });
};

export const clearSubmission = () => dispatch => {
  dispatch({ type: FETCH_TRAVELER_SUBMISSION_CLEAR });
};

export const fetchTravelerPaymentStatus = id => async dispatch => {
  await fetch(`${keys.baseUri}/api/application_submissions/${id}`, {
    method: 'GET',
    headers: token,
  })
    .then(res => {
      if (res.status === 200) {
        res.json().then(data => {
          dispatch({
            type: UPDATE_ALL_PAYMENTS_STATUS_ADMIN,
            payload: data.data.attributes.responses.filter(response => response.response_type === 'PaymentResponse'),
          });
        });
      } else {
        res.json().then(message => {
          dispatch({
            type: FETCH_TRAVELER_SUBMISSION_FAILURE,
            payload: message,
          });
        });
      }
    })
    .catch(error => {
      dispatch({
        type: FETCH_TRAVELER_SUBMISSION_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: FETCH_TRAVELER_SUBMISSION_CLEAR,
      });
    });
};

export const updateTravelerForm = (type, submissionId, body, statusId = null, refetch = true) => dispatch => {
  const typeUrl = type.toLowerCase() === 'application' ? 'application_submissions' : 'form_submissions';

  const bodyData = body || {};
  const payload = statusId ? { submission: { submission_status_id: statusId, ...bodyData.submission } } : bodyData;

  dispatch({ type: PATCH_TRAVELER_FORM_REQUEST });
  if (bodyData?.reasons) payload.reasons = bodyData.reasons;

  fetch(`${keys.baseUri}/api/${typeUrl}/${submissionId}`, {
    method: 'PATCH',
    headers: token,
    body: JSON.stringify(payload),
  }).then(response => {
    if (response.status === 200) {
      response.json().then(data => {
        if(refetch){
          setTimeout(() => {
            // dispatch({
            //   type: PATCH_TRAVELER_FORM_SUCCESS,
            //   payload: data,
            //   message: 'Form update successful',
            // });
            //
            // dispatch({
            //   type: PATCH_TRAVELER_FORM_CLEAR,
            // });
            dispatch(fetchTravelerSubmission(submissionId, true, data));
          }, 500);
        }
      });
    } else {
      response.json().then(data => {
        dispatch({
          type: PATCH_TRAVELER_FORM_FAILURE,
          message: data.errors,
        });
        dispatch({
          type: PATCH_TRAVELER_FORM_CLEAR,
        });
      });
    }
  });
};

export const updateTravelerFormTags = (type, submissionId, body, statusId = null, refetch = true) => dispatch => {
  const typeUrl = type.toLowerCase() === 'application' ? 'application_submissions' : 'application_submissions';

  const bodyData = body || {};
  const payload = statusId ? { submission: { submission_status_id: statusId, ...bodyData.submission } } : bodyData;

  dispatch({ type: PATCH_TRAVELER_FORM_REQUEST });
  if (bodyData?.reasons) payload.reasons = bodyData.reasons;

  fetch(`${keys.baseUri}/api/${typeUrl}/${submissionId}/update_tags_notes`, {
    method: 'PATCH',
    headers: token,
    body: JSON.stringify(payload),
  }).then(response => {
    if (response.status === 200) {
      response.json().then(data => {
        if(refetch){
          setTimeout(() => {
            dispatch(fetchTravelerSubmission(submissionId, true, data));
          }, 500);
        }
      });
    } else {
      response.json().then(data => {
        dispatch({
          type: PATCH_TRAVELER_FORM_FAILURE,
          message: data.errors,
        });
        dispatch({
          type: PATCH_TRAVELER_FORM_CLEAR,
        });
      });
    }
  });
};

export const requestToWithdraw = (submissionId, reason, callback = null) => dispatch => {
  dispatch({ type: REQUEST_TO_WITHDRAW_REQUEST });

  let payload = {
    submission_withdrawal_request: {
      reason: reason,
    },
  };

  fetch(`${keys.baseUri}/api/application_submissions/${submissionId}/submission_withdrawal_requests`, {
    method: 'POST',
    headers: token,
    body: JSON.stringify(payload),
  })
    .then(response => {
      if (response.status === 200 || response.status === 201) {
        response.json().then(data => {
          dispatch({
            type: REQUEST_TO_WITHDRAW_SUCCESS,
          });

          if (callback && typeof callback === 'function') {
            callback(true, 'Request submitted successfully');
          }
        });
      } else {
        response.json().then(data => {
          dispatch({
            type: REQUEST_TO_WITHDRAW_FAILURE,
            payload: data.errors,
          });

          if (callback && typeof callback === 'function') {
            callback(false, data.errors);
          }
        });
      }
    })
    .catch(error => {
      dispatch({
        type: REQUEST_TO_WITHDRAW_FAILURE,
        payload: error.message,
      });

      if (callback && typeof callback === 'function') {
        callback(false, error.message);
      }
    });
};

export const respondRequestToWithdraw = (
  submissionId,
  submissionWithdrawalRequestId,
  status,
  reasons = [],
  callback = null,
) => dispatch => {
  dispatch({ type: RESPOND_REQUEST_TO_WITHDRAW_REQUEST });

  let payload = {
    submission_withdrawal_request: {
      status: status,
    },
    application_submission: {
      reasons: reasons,
    },
  };

  fetch(
    `${keys.baseUri}/api/application_submissions/${submissionId}/submission_withdrawal_requests/${submissionWithdrawalRequestId}`,
    {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(payload),
    },
  )
    .then(response => {
      if (response.status === 200 || response.status === 201) {
        response.json().then(data => {
          dispatch({
            type: RESPOND_REQUEST_TO_WITHDRAW_SUCCESS,
          });

          if (callback && typeof callback === 'function') {
            callback(true, 'Request submitted successfully');
          }
        });
      } else {
        response.json().then(data => {
          dispatch({
            type: RESPOND_REQUEST_TO_WITHDRAW_FAILURE,
            payload: data.errors,
          });

          if (callback && typeof callback === 'function') {
            callback(false, data.errors);
          }
        });
      }
    })
    .catch(error => {
      dispatch({
        type: RESPOND_REQUEST_TO_WITHDRAW_FAILURE,
        payload: error.message,
      });

      if (callback && typeof callback === 'function') {
        callback(false, error.message);
      }
    });
};

export const fetchRecommendationSubmission = id => dispatch => {
  dispatch({ type: FETCH_VISITOR_SUBMISSION_REQUEST });
  fetch(`${keys.baseUri}/api/visitor/recommendation_submissions/${id}`, {
    method: 'GET',
    headers: token,
  })
    .then(res => {
      if (res.status === 200) {
        res.json().then(data => {
          dispatch({
            type: FETCH_VISITOR_SUBMISSION_SUCCESS,
            payload: data,
          });
        });
      } else {
        res.json().then(message => {
          dispatch({
            type: FETCH_VISITOR_SUBMISSION_FAILURE,
            payload: message,
          });
        });
      }
      dispatch({
        type: FETCH_VISITOR_SUBMISSION_CLEAR,
      });
    })
    .catch(error => {
      dispatch({
        type: FETCH_VISITOR_SUBMISSION_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: FETCH_VISITOR_SUBMISSION_CLEAR,
      });
    });
};

export const updateRecommendationForm = (id, data, recommendationToken) => dispatch => {
  dispatch({ type: PATCH_VISITOR_SUBMISSION_REQUEST });

  fetch(`${keys.baseUri}/api/visitor/recommendation_submissions/${id}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then(response => {
    if (response.status === 200) {
      response.json().then(data => {
        dispatch({
          type: PATCH_VISITOR_SUBMISSION_SUCCESS,
          payload: data,
          message: 'Form update successful',
        });
        dispatch({
          type: PATCH_VISITOR_SUBMISSION_CLEAR,
        });
        dispatch(fetchRecommendationSubmission(recommendationToken));
      });
    } else {
      response.json().then(data => {
        dispatch({
          type: PATCH_VISITOR_SUBMISSION_FAILURE,
          message: data.message,
        });
        dispatch({
          type: PATCH_VISITOR_SUBMISSION_CLEAR,
        });
      });
    }
  });
};

export const fetchTravelerAlternates = id => dispatch => {
  dispatch({ type: FETCH_TRAVELER_ALTERNATE_INFO_REQUEST });
  fetch(`${keys.baseUri}/api/traveler/applications/${id}/get_traveler_application`, {
    method: 'GET',
    headers: token,
  })
    .then(res => {
      if (res.status === 200) {
        res.json().then(data => {
          dispatch({
            type: FETCH_TRAVELER_ALTERNATE_INFO_SUCCESS,
            payload: data,
          });
        });
      } else {
        res.json().then(message => {
          dispatch({
            type: FETCH_TRAVELER_ALTERNATE_INFO_FAILURE,
            payload: message,
          });
        });
      }
      dispatch({
        type: FETCH_TRAVELER_ALTERNATE_INFO_CLEAR,
      });
    })
    .catch(error => {
      dispatch({
        type: FETCH_TRAVELER_ALTERNATE_INFO_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: FETCH_TRAVELER_ALTERNATE_INFO_CLEAR,
      });
    });
};

export const setAlternateSaveError = (value = false) => dispatch => {
  dispatch({
    type: SET_ALTERNATE_SAVE_ERROR,
    payload: value,
  });
};

export const sendRecommendationEmail = (questionId, submissionId, email) => dispatch => {
  dispatch({ type: POST_SEND_RECOMMENDATION_MAILER_REQUEST });
  fetch(`${keys.baseUri}/api/recommendation_responses/add_recommendations`, {
    method: 'POST',
    headers: token,
    body: JSON.stringify({
      response: {
        question_id: questionId,
        submission_id: submissionId,
        recommendation_requests_attributes: [{ user_email: email.trim() }],
      },
    }),
  })
    .then(res => res.json())
    .then(data => {
      dispatch({
        type: POST_SEND_RECOMMENDATION_MAILER_SUCCESS,
        payload: data,
      });
      dispatch({
        type: POST_SEND_RECOMMENDATION_MAILER_CLEAR,
      });
    })
    .catch(error => {
      dispatch({
        type: POST_SEND_RECOMMENDATION_MAILER_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: POST_SEND_RECOMMENDATION_MAILER_CLEAR,
      });
    });
};

export const cancelRecommendationEmail = recommendationId => dispatch => {
  dispatch({ type: CANCEL_SEND_RECOMMENDATION_MAILER_REQUEST });
  fetch(`${keys.baseUri}/api/recommendation_requests/${recommendationId}`, {
    method: 'DELETE',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      dispatch({
        type: CANCEL_SEND_RECOMMENDATION_MAILER_SUCCESS,
        payload: data,
      });
      dispatch({
        type: CANCEL_SEND_RECOMMENDATION_MAILER_CLEAR,
      });
    })
    .catch(error => {
      dispatch({
        type: CANCEL_SEND_RECOMMENDATION_MAILER_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: CANCEL_SEND_RECOMMENDATION_MAILER_CLEAR,
      });
    });
};

export const resendRecommendationEmail = recommendationId => dispatch => {
  dispatch({ type: RESEND_RECOMMENDATION_MAILER_REQUEST });
  fetch(`${keys.baseUri}/api/recommendation_requests/${recommendationId}`, {
    method: 'PATCH',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      dispatch({
        type: RESEND_RECOMMENDATION_MAILER_SUCCESS,
        payload: data,
      });
      dispatch({
        type: RESEND_RECOMMENDATION_MAILER_CLEAR,
      });
    })
    .catch(error => {
      dispatch({
        type: RESEND_RECOMMENDATION_MAILER_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: RESEND_RECOMMENDATION_MAILER_CLEAR,
      });
    });
};

export const uploadFileAdd = (saveValues, isVisitor = false) => dispatch => {
  const setBody = JSON.stringify({
    response: saveValues,
  });

  const apiPath = isVisitor ? 'visitor/recommendation_submissions/add_files' : 'file_upload_responses/add_files';
  const customHeaders = isVisitor
    ? {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    : token;

  dispatch({ type: UPLOAD_FILE_ADD_REQUEST });
  fetch(`${keys.baseUri}/api/${apiPath}`, {
    method: 'POST',
    headers: customHeaders,
    body: setBody,
  })
    .then(res => res.json())
    .then(data => {
      dispatch({
        type: UPLOAD_FILE_ADD_SUCCESS,
        payload: data,
      });
      dispatch({
        type: UPDATE_FORM_SUBMISSION_RESPONSE,
        payload: data,
      });
      dispatch({
        type: UPLOAD_FILE_ADD_CLEAR,
      });
    })
    .catch(error => {
      dispatch({
        type: UPLOAD_FILE_ADD_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: UPLOAD_FILE_ADD_CLEAR,
      });
    });
};

export const uploadFileDelete = (saveValues, isVisitor = false) => dispatch => {
  const setBody = JSON.stringify({
    response: saveValues,
  });

  const apiPath = isVisitor ? 'visitor/recommendation_submissions/remove_files' : 'file_upload_responses/remove_files';
  const customHeaders = isVisitor
    ? {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    : token;

  dispatch({ type: UPLOAD_FILE_DELETE_REQUEST });
  fetch(`${keys.baseUri}/api/${apiPath}`, {
    method: 'DELETE',
    headers: customHeaders,
    body: setBody,
  })
    .then(res => res.json())
    .then(data => {
      dispatch({
        type: UPLOAD_FILE_DELETE_SUCCESS,
        payload: data,
        question_id: saveValues?.question_id,
      });
      dispatch({
        type: UPDATE_FORM_SUBMISSION_RESPONSE,
        payload: data,
      });
      dispatch({
        type: UPLOAD_FILE_DELETE_CLEAR,
      });
    })
    .catch(error => {
      dispatch({
        type: UPLOAD_FILE_DELETE_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: UPLOAD_FILE_DELETE_CLEAR,
      });
    });
};

export const getPaymentStatusOptions = () => dispatch => {
  dispatch({ type: PAYMENT_STATUS_OPTIONS_REQUEST });
  fetch(`${keys.baseUri}/api/payment_statuses`, {
    method: 'GET',
    headers: token,
  })
    .then(res => {
      if (res.status === 200) {
        res.json().then(data => {
          dispatch({
            type: PAYMENT_STATUS_OPTIONS_SUCCESS,
            payload: data,
          });
        });
      } else {
        res.json().then(message => {
          dispatch({
            type: PAYMENT_STATUS_OPTIONS_FAILURE,
            payload: message,
          });
        });
      }

      dispatch({
        type: PAYMENT_STATUS_OPTIONS_CLEAR,
      });
    })
    .catch(error => {
      dispatch({
        type: PAYMENT_STATUS_OPTIONS_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: PAYMENT_STATUS_OPTIONS_CLEAR,
      });
    });
};

export const getTravelerInfoFields = () => dispatch => {
  dispatch({ type: FETCH_TRAVELER_INFO_FIELDS_REQUEST });
  fetch(`${keys.baseUri}/api/traveler_info_fields`, {
    method: 'GET',
    headers: token,
  })
    .then(res => {
      if (res.status === 200) {
        res.json().then(data => {
          dispatch({
            type: FETCH_TRAVELER_INFO_SUCCESS,
            payload: data,
          });
        });
      } else {
        res.json().then(message => {
          dispatch({
            type: FETCH_TRAVELER_INFO_FIELDS_FAILURE,
            payload: message,
          });
        });
      }

      dispatch({
        type: FETCH_TRAVELER_INFO_FIELDS_CLEAR,
      });
    })
    .catch(error => {
      dispatch({
        type: FETCH_TRAVELER_INFO_FIELDS_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: FETCH_TRAVELER_INFO_FIELDS_CLEAR,
      });
    });
};

export const fetchAvailableAlternates = (id, travelerId) => dispatch => {
  dispatch({ type: FETCH_AVAILABLE_ALTERNATES_REQUEST });
  fetch(`${keys.baseUri}/api/program_ranges/${id}/available_for_alternates?traveler_id=${travelerId}`, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      dispatch({
        type: FETCH_AVAILABLE_ALTERNATES_SUCCESS,
        payload: data,
      });
    })
    .catch(error => {
      dispatch({
        type: FETCH_AVAILABLE_ALTERNATES_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: FETCH_AVAILABLE_ALTERNATES_CLEAR,
      });
    });
};

export const fetchAvailableApplications = (travelerId, termId = '') => dispatch => {
  dispatch({ type: FETCH_AVAILABLE_APPLICATIONS_REQUEST });
  let url = `${keys.baseUri}/api/programs/available_for_application/${travelerId}`;
  if (termId) {
    url += `?term_id=${termId}`;
  }
  fetch(url, {
    method: 'GET',
    headers: token,
  })
    .then(res => res.json())
    .then(data => {
      dispatch({
        type: FETCH_AVAILABLE_APPLICATIONS_SUCCESS,
        payload: data,
      });
    })
    .catch(error => {
      dispatch({
        type: FETCH_AVAILABLE_APPLICATIONS_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: FETCH_AVAILABLE_APPLICATIONS_CLEAR,
      });
    });
};

export const transferApplication = (data, application_id) => dispatch => {
  fetch(`${keys.baseUri}/api/application_submissions/${application_id}/transfer`, {
    method: 'POST',
    headers: token,
    body: JSON.stringify(data),
  })
    .then(res => res.json())
    .then(data => {
      if (data.error) {
        dispatch({
          type: SET_TRANSFER_APPLICATION_ERROR,
          payload: data.error,
        });
      } else {
        dispatch({
          type: SET_TRANSFER_APPLICATION_SUCCESS,
          payload: data,
        });
      }
    });
};

export const processFlywirePayment = (payload, isAdmin) => dispatch => {
  fetch(`${keys.baseUri}/api/flywire/postback`, {
    method: 'POST',
    headers: token,
    body: JSON.stringify(payload),
  })
    .then(res => res.json())
    .then(data => {
      dispatch({
        type: isAdmin ? PAYMENT_STATUS_FLYWIRE_UPDATE_ADMIN : PAYMENT_STATUS_FLYWIRE_UPDATE,
        payload: data,
      });
    })
    .catch(error => {
      //Handle failure
      // eslint-disable-next-line no-console
      console.error('Failure to update payment status', error);
    });
};

export const addFormForTraveler = data => dispatch => {
  dispatch({ type: ADMIN_ADD_FORMS_REQUEST });
  const numberOfForms = data.template_ids.length;
  fetch(`${keys.baseUri}/api/submission_batch_actions/add_forms`, {
    method: 'POST',
    headers: token,
    body: JSON.stringify(data),
  })
    .then(res => res.json())
    .then(data => {
      //fixing broken endpoint data
      // data.success_count = numberOfForms;

      let formCount = Object.values(data.form_counts).reduce((acc, curr) => acc + curr, 0);
      data = { ...data, formCount };

      dispatch({
        type: ADMIN_ADD_FORMS_SUCCESS,
        payload: data,
      });
      dispatch({
        type: ADMIN_ADD_FORMS_CLEAR,
      });
    })
    .catch(error => {
      dispatch({
        type: ADMIN_ADD_FORMS_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: ADMIN_ADD_FORMS_CLEAR,
      });
    });
};

export const deleteTravelerApplication = id => dispatch => {
  dispatch({ type: DELETE_TRAVELER_APPLICATION_REQUEST });
  fetch(`${keys.baseUri}/api/application_submissions/${id}`, {
    method: 'DELETE',
    headers: token,
  })
    .then(response => {
      if (response.status === 204) {
        dispatch({
          type: DELETE_TRAVELER_APPLICATION_SUCCESS,
          message: 'Application deleted',
        });

        dispatch({
          type: DELETE_TRAVELER_APPLICATION_CLEAR,
        });
      } else {
        dispatch({
          type: DELETE_TRAVELER_APPLICATION_FAILURE,
          message: 'Error deleting application',
        });
        dispatch({
          type: DELETE_TRAVELER_APPLICATION_CLEAR,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: DELETE_TRAVELER_APPLICATION_FAILURE,
        message: 'Error deleting application',
      });
      dispatch({
        type: DELETE_TRAVELER_APPLICATION_CLEAR,
      });
    });
};

export const addFormsToTravelerProfile = data => dispatch => {
  dispatch({ type: ADD_FORMS_TO_TRAVELER_PROFILE_REQUEST });
  fetch(`${keys.baseUri}/api/form_submissions`, {
    method: 'POST',
    headers: token,
    body: JSON.stringify({ submissions: data }),
  })
    .then(res => res.json())
    .then(data => {
      dispatch({
        type: ADD_FORMS_TO_TRAVELER_PROFILE_SUCCESS,
        payload: data,
      });
      dispatch({
        type: ADD_FORMS_TO_TRAVELER_PROFILE_CLEAR,
      });
    })
    .then(() => dispatch(getTravelerApplicationSubmissions(data.map(item => item.user_id)[0])))
    .catch(error => {
      dispatch({
        type: ADD_FORMS_TO_TRAVELER_PROFILE_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: ADD_FORMS_TO_TRAVELER_PROFILE_CLEAR,
      });
    });
};

export const addApplicationToTravelerProfile = (data, override) => dispatch => {
  dispatch({ type: ADD_APPLICATION_TO_TRAVELER_PROFILE_REQUEST });

  let requestData = {};
  if (override === 1) {
    requestData = {
      submission: { program_range_id: data.program_range_id, user_id: data.user_id },
      override_application_timeframe_violation: true,
    };
  } else {
    requestData = { submission: data };
  }

  fetch(`${keys.baseUri}/api/application_submissions`, {
    method: 'POST',
    headers: token,
    body: JSON.stringify(requestData),
  })
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        res
          .json()
          .then(data => {
            dispatch({
              type: ADD_APPLICATION_TO_TRAVELER_PROFILE_SUCCESS,
              payload: data,
            });

            dispatch({
              type: ADD_APPLICATION_TO_TRAVELER_PROFILE_CLEAR,
            });
          })
          .then(() => dispatch(getTravelerApplicationSubmissions(data.user_id)));
      } else if (res.status === 422) {
        res.json().then(message => {
          dispatch({
            type: ADD_APPLICATION_TO_TRAVELER_PROFILE_FAILURE,
            payload: { message, isViolateTermRule: true },
          });

          dispatch({
            type: ADD_APPLICATION_TO_TRAVELER_PROFILE_CLEAR,
          });
        });
      } else {
        res.json().then(message => {
          dispatch({
            type: ADD_APPLICATION_TO_TRAVELER_PROFILE_FAILURE,
            payload: message,
          });

          dispatch({
            type: ADD_APPLICATION_TO_TRAVELER_PROFILE_CLEAR,
          });
        });
      }
    })
    .catch(error => {
      dispatch({
        type: ADD_APPLICATION_TO_TRAVELER_PROFILE_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: ADD_APPLICATION_TO_TRAVELER_PROFILE_CLEAR,
      });
    });
};

export const getAvailableForms = user_id => dispatch => {
  dispatch({ type: GET_AVAILABLE_FORMS_REQUEST });
  fetch(`${keys.baseUri}/api/form_templates/available_for_traveler_terms?user_id=${user_id}`, {
    method: 'GET',
    headers: token,
  })
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        res.json().then(data => {
          dispatch({
            type: GET_AVAILABLE_FORMS_SUCCESS,
            payload: data,
          });
        });
      } else {
        res.json().then(message => {
          dispatch({
            type: GET_AVAILABLE_FORMS_FAILURE,
            payload: message,
          });
        });
      }

      dispatch({
        type: GET_AVAILABLE_FORMS_CLEAR,
      });
    })
    .catch(error => {
      dispatch({
        type: GET_AVAILABLE_FORMS_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: GET_AVAILABLE_FORMS_CLEAR,
      });
    });
};

export const getAvailableTags = (tag_type, callback = null) => dispatch => {
  dispatch({ type: FETCH_AVAILABLE_TAG_REQUEST });
  let url = `${keys.baseUri}/api/taggable/tags`;
  if (tag_type) {
    url += `?tag_type=${tag_type}`;
  }
  fetch(url, {
    method: 'GET',
    headers: token,
  })
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        res.json().then(data => {
          dispatch({
            type: FETCH_AVAILABLE_TAG_SUCCESS,
            payload: data,
          });

          if (callback && typeof callback === 'function') {
            callback(true, data);
          }
        });
      } else {
        res.json().then(message => {
          dispatch({
            type: FETCH_AVAILABLE_TAG_FAILURE,
            payload: message,
          });

          if (callback && typeof callback === 'function') {
            callback(false, message);
          }
        });
      }

      dispatch({
        type: FETCH_AVAILABLE_TAG_CLEAR,
      });
    })
    .catch(error => {
      dispatch({
        type: FETCH_AVAILABLE_TAG_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: FETCH_AVAILABLE_TAG_CLEAR,
      });

      if (callback && typeof callback === 'function') {
        callback(false, error?.message);
      }
    });
};

export const getTagColors = () => dispatch => {
  dispatch({ type: FETCH_TAG_COLORS_REQUEST });
  let url = `${keys.baseUri}/api/taggable/tag_colors`;
  fetch(url, {
    method: 'GET',
    headers: token,
  })
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        res.json().then(data => {
          dispatch({
            type: FETCH_TAG_COLORS_SUCCESS,
            payload: data,
          });
        });
      } else {
        res.json().then(message => {
          dispatch({
            type: FETCH_TAG_COLORS_FAILURE,
            payload: message,
          });
        });
      }

      dispatch({
        type: FETCH_TAG_COLORS_CLEAR,
      });
    })
    .catch(error => {
      dispatch({
        type: FETCH_TAG_COLORS_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: FETCH_TAG_COLORS_CLEAR,
      });
    });
};

export const attachTag = (tagID, applicationID) => dispatch => {
  const data = {
    attach_tags: {
      tag_id: tagID,
      taggable_id: applicationID,
    },
  };
  dispatch({ type: ATTACH_TAG_REQUEST });
  fetch(`${keys.baseUri}/api/taggable/tags/attach_tags`, {
    method: 'POST',
    headers: token,
    body: JSON.stringify(data),
  }).then(response => {
    if (response.status === 200) {
      response.json().then(data => {
        dispatch({
          type: ATTACH_TAG_SUCCESS,
          payload: data,
        });
        dispatch({
          type: ATTACH_TAG_CLEAR,
        });
      });
    } else {
      response.json().then(message => {
        dispatch({
          type: ATTACH_TAG_FAILURE,
          message: message,
        });
        dispatch({
          type: ATTACH_TAG_CLEAR,
        });
      });
    }
  });
};

export const deleteSubmissionTag = (tag_id, tagName, callback) => dispatch => {
  dispatch({ type: DELETE_SUBMISSION_TAG_REQUEST });
  let url = `${keys.baseUri}/api/taggable/tags/${tag_id}`;
  fetch(url, {
    method: 'DELETE',
    headers: token,
  })
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        res.json().then(data => {
          if (callback && typeof callback === 'function') {
            callback(true, `${tagName} deleted`);
          }

          dispatch({
            type: DELETE_SUBMISSION_TAG_SUCCESS,
            payload: data,
          });
        });
      } else {
        res.json().then(message => {
          if (callback && typeof callback === 'function') {
            callback(false, 'Something went wrong, please try again later');
          }

          dispatch({
            type: DELETE_SUBMISSION_TAG_FAILURE,
            payload: message,
          });
        });
      }
    })
    .catch(error => {
      if (callback && typeof callback === 'function') {
        callback(false, 'Something went wrong, please try again later');
      }

      dispatch({
        type: DELETE_SUBMISSION_TAG_FAILURE,
        payload: error.toString(),
      });
    });
};

export const createSubmissionTag = (data, callback) => dispatch => {
  dispatch({ type: CREATE_SUBMISSION_TAG_REQUEST });
  fetch(`${keys.baseUri}/api/taggable/tags`, {
    method: 'POST',
    headers: token,
    body: JSON.stringify(data),
  })
    .then(response => {
      if (response.status === 200) {
        response.json().then(data => {
          if (callback && typeof callback === 'function') {
            callback(true, `${data.data.attributes.name} created`);
          }
          dispatch({
            type: CREATE_SUBMISSION_TAG_SUCCESS,
            payload: data,
          });
          dispatch({
            type: CREATE_SUBMISSION_TAG_CLEAR,
          });
        });
      } else {
        response.json().then(message => {
          let error = 'Something went wrong, please try again later';
          const keyName = Object.keys(message?.error)[0];
          const capitalizedKeyName = keyName.charAt(0).toUpperCase() + keyName.slice(1);
          if (message?.error?.name[0]) {
            error = capitalizedKeyName + ' ' + message.error.name[0];
          }
          if (callback && typeof callback === 'function') {
            callback(false, error);
          }
          dispatch({
            type: CREATE_SUBMISSION_TAG_FAILURE,
            message: message,
          });
          dispatch({
            type: CREATE_SUBMISSION_TAG_CLEAR,
          });
        });
      }
    })
    .catch(error => {
      if (callback && typeof callback === 'function') {
        callback(false, error);
      }
      dispatch({
        type: CREATE_SUBMISSION_TAG_FAILURE,
        payload: error.toString(),
      });
    });
};

export const updateSubmissionTag = (data, tagId, callback) => dispatch => {
  dispatch({ type: UPDATE_SUBMISSION_TAG_REQUEST });
  fetch(`${keys.baseUri}/api/taggable/tags/${tagId}`, {
    method: 'PATCH',
    headers: token,
    body: JSON.stringify(data),
  })
    .then(response => {
      if (response.status === 200) {
        response.json().then(data => {
          if (callback && typeof callback === 'function') {
            callback(true, `${data.data.attributes.name} saved`);
          }
          dispatch({
            type: UPDATE_SUBMISSION_TAG_SUCCESS,
            payload: data,
          });
          dispatch({
            type: UPDATE_SUBMISSION_TAG_CLEAR,
          });
        });
      } else {
        response.json().then(message => {
          if (callback && typeof callback === 'function') {
            callback(false, 'Something went wrong, please try again later');
          }
          dispatch({
            type: UPDATE_SUBMISSION_TAG_FAILURE,
            message: message,
          });
          dispatch({
            type: UPDATE_SUBMISSION_TAG_CLEAR,
          });
        });
      }
    })
    .catch(error => {
      if (callback && typeof callback === 'function') {
        callback(false, 'Something went wrong, please try again later');
      }
      dispatch({
        type: CREATE_SUBMISSION_TAG_FAILURE,
        payload: error.toString(),
      });
    });
};

export const getTokens = (tokenable_id = '', tokenable_name = '') => dispatch => {
  dispatch({ type: GET_TOKENS_REQUEST });
  let url = `${keys.baseUri}/api/client/tokens?tokenable_type=TemplateType&tokenable_id=${tokenable_id}&tokenable_name=${tokenable_name}`;
  fetch(url, {
    method: 'GET',
    headers: token,
  })
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        res.json().then(data => {
          dispatch({
            type: GET_TOKENS_SUCCESS,
            payload: data?.data,
          });
        });
      } else {
        res.json().then(message => {
          dispatch({
            type: GET_TOKENS_FAILURE,
            payload: message,
          });
        });
      }

      dispatch({
        type: GET_TOKENS_FAILURE,
      });
    })
    .catch(error => {
      dispatch({
        type: GET_TOKENS_FAILURE,
        payload: error.toString(),
      });
      dispatch({
        type: GET_TOKENS_FAILURE,
      });
    });
};

export const downloadBatchZip = (payload, callback = null) => async dispatch => {
  try {
    let postData = {
      batch_download: {
        submission_ids: payload.submission_ids,
        response_downloadable: payload.response_downloadable,
        upload_files: payload.upload_files,
        type: payload.type,
      },
    };

    dispatch({
      type: BATCH_ZIP_DOWNLOAD_REQUEST,
    });

    let response = await fetch(`${keys.baseUri}/api/batch_download/batch_zip_downloads`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(postData),
    });

    if (response.status === 200 || response.status === 201) {
      let data = await response.json();
      dispatch({
        type: BATCH_ZIP_DOWNLOAD_SUCCESS,
        payload: data.data,
      });

      if (callback && typeof callback === 'function') {
        callback(true, 'Zip file is generating. View Downloads');
      }
    } else {
      throw new Error('Something went wrong');
    }
  } catch (e) {
    dispatch({
      type: BATCH_ZIP_DOWNLOAD_FAILURE,
      payload: e.message,
    });

    callback(false, e.message);
  }
};

export function fetchWithdrawDefferedReason() {
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/deferred_and_withdrawal_reasons`, { headers: token })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FETCH_WITHDRAW_DEFFERED_REASON,
          payload: data,
        }),
      );
  };
}
