import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import StyledIcon from '../styles/Icon.styled';
import Tooltip from './Tooltip.styled';
import TooltipArrow from './TooltipArrow.styled';
import { stripHtmlString } from '../../shared/helpers/General';
import SyncIcon from '../../sites/travelerProfile/components/icons/SyncIcon';
import EyeIconClose from '../../shared/images/eye-icon-close.png';
import EyeIconOpen from '../../shared/images/eye-icon-open.png';
const openSansFont = css`
  font-family: ${({ theme }) => theme.textInput.fontFamilyOpenSans};
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const InputInnerGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const InputLabel = styled.label`
  color: ${({ theme, isTravelerAccount }) => (isTravelerAccount ? '#373737' : theme.textInput.label.fontColor)};
  font-family: ${({ theme, isTravelerAccount }) =>
    isTravelerAccount ? "'Nunito',sans-serif" : theme.textInput.label.fontFamily};
  font-size: ${({ theme, isTravelerAccount }) => (isTravelerAccount ? '0.875rem' : theme.textInput.label.fontSize)};
  font-weight: ${({ theme }) => theme.textInput.label.fontWeight};

  ${({ openSans }) => (openSans ? openSansFont : null)};
`;

const Instructions = styled.div`
  color: ${({ theme }) => theme.textInput.instructions.fontColor};
  font-family: ${({ theme }) => theme.textInput.instructions.fontFamily};
  font-size: ${({ theme }) => theme.textInput.instructions.fontSize};
  font-weight: ${({ theme }) => theme.textInput.instructions.fontWeight};
  padding-left: 5px;
  overflow-wrap: anywhere;
  p {
    margin-bottom: 0px;
  }
  ${({ openSans }) => (openSans ? openSansFont : null)};
`;

const InputField = styled.input`
  border: ${props =>
    props.errorMsg && props.errorMsg.length > 0
      ? ({ theme }) => theme.textInput.error.border
      : ({ theme }) => theme.textInput.border};
  border-radius: ${({ theme, inputBorderRadius }) => inputBorderRadius ? inputBorderRadius : theme.textInput.borderRadius};
  color: ${({ theme }) => theme.textInput.fontColor};
  font-family: ${({ theme }) => theme.textInput.fontFamily};
  font-size: ${({ theme,inputFontSize }) => inputFontSize ? inputFontSize : theme.textInput.fontSize};
  font-weight: ${({ theme }) => theme.textInput.fontWeight};
  height: ${({ theme }) => theme.textInput.height};
  margin-bottom: 0;
  line-height: ${({ theme }) => theme.textInput.lineHeight};
  padding: ${({ theme }) => theme.textInput.padding};
  width: 100%;

  ${({ openSans }) => (openSans ? openSansFont : null)} &:disabled {
    border: ${({ theme }) => theme.textInput.disabled.border};
    background-color: ${({ theme }) => theme.textInput.disabled.backgroundColor};
    color: ${({ theme }) => theme.textInput.disabled.fontColor};
    cursor: not-allowed;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline-color: ${({ theme }) => theme.textInput.focus.outlineColor};
  }

  &::placeholder {
    color: ${({ theme, isTravelerAccount }) => (isTravelerAccount ? '#555555' : theme.textInput.placeholder.fontColor)};
    font-family: ${({ theme, isTravelerAccount }) =>
    isTravelerAccount ? "'Open Sans', sans-serif" : theme.textInput.placeholder.fontFamily};
    font-size: ${({ theme, isTravelerAccount }) =>
    isTravelerAccount ? '0.875rem' : theme.textInput.placeholder.fontSize};
    font-style: ${({ theme }) => theme.textInput.placeholder.fontStyle};
    font-weight: ${({ theme, isTravelerAccount }) => (isTravelerAccount ? '' : theme.textInput.placeholder.fontWeight)};
    font-style: ${({ theme, placeholderFontStyle }) => (placeholderFontStyle ? placeholderFontStyle : '')};
    letter-spacing: ${({ isTravelerAccount }) => (isTravelerAccount ? '0.015em' : null)};
    ${({ openSans }) => (openSans ? openSansFont : null)};
  }
`;

const Error = styled.div`
  color: ${({ theme }) => theme.textInput.error.fontColor};
  display: inline-block;
  font-family: ${({ theme }) => theme.textInput.error.fontFamily};
  font-size: ${({ theme }) => theme.textInput.error.fontSize};
  font-weight: ${({ theme }) => theme.textInput.error.fontWeight};
  padding-top: 3px;
  vertical-align: middle;
  ${({ openSans }) => (openSans ? openSansFont : null)} svg {
    justify-content: center;
    margin-right: 5px;

    vertical-align: middle;
    display: inline-block;
  }

  span {
    vertical-align: middle;
    // display: inline-block;
  }
`;

const Required = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
  padding-left: 5px;
`;

const TooltipContainer = styled.span`
  padding-left: 5px;
`;

const IconDiv = styled.div`
  color: ${({ theme }) => theme.textInput.disabled.fontColor};
  position: relative;
  right: 28px;
  top: 9px;
  width: 0;
`;

const IconButton = styled.button`
  color: ${({ theme }) => theme.textInput.disabled.fontColor};
  position: relative;
  right: 28px;
  // top: 9px;
  top: 0; 
  width: 0;
  border: none; 
  background: transparent; 
  padding: 0; 
`;

const SensitiveIconButton = styled(IconButton)`
  color: ${props => (props.disabled ? '#696969' : '#043544')};
  cursor: pointer;
`;


const SensitiveIconDiv = styled(IconDiv)`
  color: ${props => (props.disabled ? '#696969' : '#043544')};
  cursor: pointer;
`;

const Sig = styled.div`
  font-family: 'Homemade Apple', sans-serif;
  font-size: 22px;
  line-height: 26px;
  padding-left: 10px;
  padding-top: 10px;
  overflow-wrap: anywhere; 
`;

const CharLimit = styled.div`
  color: #555555;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const TextInput = ({
  disabled,
  errorMsg,
  icon,
  id,
  name,
  label,
  placeholder,
  required,
  tooltip,
  value,
  instructions,
  showSignature,
  defaultValue,
  charLimit,
  synced = false,
  placeholderFontStyle,
  inputBorderRadius,
  inputFontSize,
  readAlertCustom,
  autoComplete,
  isTooltipArrow=false,
  ...props
}) => {
  const [inputType, setInputType] = useState('text');
  const [toggleShow, setToggleShow] = useState(!props.sensitive);
  const [openSansFont, setOpenSansFont] = useState(props.openSans);
  const [isTravelerAccount, setIsTravelerAccount] = useState(props.isTravelerAccount ? true : false);
  let cleanseInstructions = null;
  if (instructions && stripHtmlString(instructions).trim().length > 0) {
    const updatedIntro = !!instructions ? instructions.replace(/<p><\/p>/g, '<br />') : '';
    cleanseInstructions = updatedIntro;
  }
  
  useEffect(() => {
    if (!toggleShow) {
      setInputType('password');
    } else if (props.type) {
      setInputType(props.type);
    } else {
      setInputType('text');
    }
  }, [props]);
  return (
    <InputGroup>
      {label && label.length > 0 && (
        <InputLabel htmlFor={id} openSans={openSansFont} style={props.customLabelStyle} isTravelerAccount={isTravelerAccount}>
          {label}
          {required && <Required>*</Required>}
          {synced && <SyncIcon />}
          {tooltip && (
            <TooltipContainer>
              {isTooltipArrow ? 
                <TooltipArrow toolTipText={tooltip} top="-64px" left="-429%">
                  <StyledIcon type={'InformationCircle'} size={'14px'} />
                </TooltipArrow>
              :
                <Tooltip toolTipText={tooltip}>
                  <StyledIcon type={'InformationCircle'} size={'14px'} />
                </Tooltip>
              }
            </TooltipContainer>
          )}
        </InputLabel>
      )}
      {cleanseInstructions && cleanseInstructions.length > 0 && (
        <Instructions
          openSans={openSansFont}
          dangerouslySetInnerHTML={{
            __html: cleanseInstructions,
          }}
        />
      )}
      <InputInnerGroup>
        <InputField
          aria-invalid={errorMsg && errorMsg.length > 0 ? 'true' : 'false'}
          aria-label={label || 'Text input'}
          errorMsg={errorMsg}
          value={value}
          defaultValue={defaultValue}
          disabled={disabled}
          id={id}
          name={name}
          placeholder={placeholder}
          placeholderFontStyle={placeholderFontStyle}
          inputBorderRadius={inputBorderRadius}
          inputFontSize={inputFontSize}
          openSans={openSansFont}
          isTravelerAccount={isTravelerAccount}
          type={inputType}
          //aria-describedby={(!!charLimit && parseInt(charLimit) > 0) ? `characterCount-${id}` : ""}
          maxLength={charLimit || ''}
          onKeyDown={e => {
            if (inputType === 'number' && (e.keyCode === 69 || e.keyCode === 189)) {
              e.preventDefault();
            }
          }}
          style={props.customInputstyle}
          autoComplete={autoComplete || label}
          {...props}
        />
        {!props.sensitive && icon && icon.length > 0 && (
          <IconDiv>
            <StyledIcon type={icon} size={'16px'} />
          </IconDiv>
        )}
        {props.sensitive && (
          <SensitiveIconButton
            onClick={() => setToggleShow(!toggleShow)}
            aria-label={'Toggle button to show/hide sensitive information'}
            disabled={disabled}
            tabIndex={0}
            type="button"
          >
            <img src={toggleShow ? EyeIconClose : EyeIconOpen} width="16" height={"16"} alt={"Toggle show/hide sensitive information"} />
          </SensitiveIconButton>
        )}
       
        {/* {props.sensitive && !toggleShow && (
          <SensitiveIconButton
            onClick={() => setToggleShow(!toggleShow)}
            aria-label={'Toggle button to show sensitive information'}
            onKeyDown={keyEvent => {
              if ((keyEvent.charCode || keyEvent.keyCode) === 13 || (keyEvent.charCode || keyEvent.keyCode) === 32) {
                setToggleShow(!toggleShow);
              }
            }}
            disabled={disabled}
            tabIndex={0}
          >
           <img src={EyeIconOpen} width="16" height={"16"} />
          </SensitiveIconButton>
        )} */}
        
      </InputInnerGroup>

      <div aria-live="polite" id={`characterCount-${id}`}>
        {!!charLimit && parseInt(charLimit) > 0 && (
          <CharLimit aria-live="polite">{charLimit - (parseInt(value?.length) || 0)} Characters Remaining</CharLimit>
        )}

        {errorMsg && errorMsg.length > 0 && (
          <Error openSans={openSansFont}>
            <StyledIcon type={'Warning'} size={'16px'} />
            {readAlertCustom ?
            <span>{errorMsg}</span>
            :
            <span role="alert" aria-live="assertive">{errorMsg}</span>
          }
          </Error>
        )}
      </div>


      {showSignature && value && <Sig>{value}</Sig>}
    </InputGroup>
  );
};

export default TextInput;