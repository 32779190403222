exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes LiVZd{to{-webkit-transform:rotate(1turn)}}@keyframes LiVZd{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._8rE1{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-direction:row;flex-direction:row;padding:25px;border-bottom:5px solid #ddaf64}._2oj2W{color:#373737;text-align:center;font-family:Nunito;font-size:25px;font-style:normal;font-weight:400;line-height:120%}._2eRhr{outline:none;border:none;padding:0;background:none}._3CJkk{-ms-flex-direction:column;flex-direction:column}._1tc-e,._3CJkk{display:-ms-flexbox;display:flex}._1tc-e{-ms-flex-item-align:center;align-self:center;margin:0;padding:0;text-align:center}.aGVvY{width:30%;margin:auto}.EYy5a{width:70%;padding:30px}._3gtr_{color:#373737;font-weight:400;text-align:left}._3gtr_,._3gtr_ strong{font-family:Open Sans;font-size:16px;font-style:normal;line-height:140%;letter-spacing:.08px}._3gtr_ strong{color:#255a94;color:var(--info-text,#255a94);font-weight:700;-webkit-text-decoration-line:underline;text-decoration-line:underline}.mRrjl{background:#ebebeb;display:-ms-flexbox;display:flex;height:111px;-ms-flex-pack:center;justify-content:center;left:0;top:273px;width:100%;margin-bottom:-20px;gap:30px}._2w_1R{margin-top:auto;margin-bottom:auto}._2D_Nq{-ms-flex-item-align:center;align-self:center;color:#a82425;font-family:AvenirNextRegular,sans-serif;font-size:14px}._2D_Nq svg{-ms-flex-pack:center;justify-content:center;margin-bottom:2px;margin-right:5px}", ""]);

// exports
exports.locals = {
	"header": "_8rE1",
	"heading": "_2oj2W",
	"closeButton": "_2eRhr",
	"modalContainer": "_3CJkk",
	"content": "_1tc-e",
	"iconWrapper": "aGVvY",
	"paragraphContainer": "EYy5a",
	"paragraphWarning": "_3gtr_",
	"buttonWrapper": "mRrjl",
	"buttonItem": "_2w_1R",
	"errorWrapper": "_2D_Nq",
	"spin": "LiVZd"
};