import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints } from '../../utils/breakpoints';
import { buttonFocus } from '../../utils/shared/focusStyles';

export const TabContentContainer = styled.section`
  max-width: 60.375rem;
  margin: 0 auto;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  padding: 0 0.75rem;

  @media ${breakpoints.tablet} {
    margin-top: 0;
  }

  @media ${breakpoints.desktop} {
    gap: 1.9375rem;
    margin-top: 1.5rem;
    padding: 0;
  }
`;

export const TabCard = styled.div`
  background: #ffffff;

  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  row-gap: 6.875rem;

  @media ${breakpoints.tablet} {
    gap: 2.25rem;
    padding: 2.8125rem 3.5rem;
  }
  ${props => {
      if(props.isBrochureSection) {
        return `
          display: flex;
          background: var(--off-white, #EEE);
          padding : 1.8125rem 1.5rem;
          @media ${breakpoints.tablet} {
            padding : 1.8125rem 1.5rem;
          }
          p {
            margin-top:0px;
            margin-bottom:0px;
          }
        `;
      }
    }
  }
  ${props => {
    if (props.noTabCardMobile) {
      return `
        @media ${breakpoints.tablet} {
          box-shadow: 0.25rem 0.25rem 0.9375rem 0.0625rem rgba(0, 0, 0, 0.15);
          padding: 1.6875rem 1.125rem;
          border: 0.0625rem solid #ebebeb;
          border-radius: 10px;
        }
      `;
    } else {
      return `
        box-shadow: 0.25rem 0.25rem 0.9375rem 0.0625rem rgba(0, 0, 0, 0.15);
        padding: 1.6875rem 1.125rem;
        border: 0.0625rem solid #ebebeb;
        border-radius: 10px;
      `;
    }

    
  }};
`;

export const TabCardPanel = styled.div`
  display: flex;
  flex-direction: column;
  &.left {
    gap: 2.875rem;
    grid-column: span 12 / span 12;
    order: 2;

    @media ${breakpoints.tablet} {
      grid-column: span 7 / span 7;
      order: 1;
    }
  }
  &.right {
    grid-column: span 12 / span 12;
    order: 1;

    @media ${breakpoints.tablet} {
      grid-column: span 5 / span 5;
      order: 2;
    }
  }
  &.full {
    grid-column: span 12 / span 12;
  }
`;

export const TabReadMoreLink = styled.button`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 120%;
  letter-spacing: 0.04em;
  text-decoration-line: underline;
  color: #446372;
  border: 2px solid transparent;
  border-radius: 2px;
  padding: 0;
  background: none;

  ${buttonFocus};
`;

const Heading = styled.h1`
  font-family: 'Nunito', sans-serif;
  letter-spacing: 0.0025em;
  color: #373737;
  font-weight: 300;
  line-height: 120%;
  ${props => {
    switch (props.level) {
      case 1:
        return `
          font-size: 1.3125rem;

          @media ${breakpoints.tablet} {
            font-size: 1.75rem;
          }
        `;
      case 2:
        return `
          font-size: 1.3125rem;
          margin-bottom: 1.3125rem;

          @media ${breakpoints.tablet} {
            font-size: 1.75rem;
          }
        `;
      case 3:
        return `
          font-size: 1.125rem;
          margin-bottom: 1.3125rem;

          @media ${breakpoints.tablet} {
            font-size: 1.5rem;
          }
        `;
      case 4:
        return `
          font-weight: 500;
          font-size: 0.875rem;
          letter-spacing: 0.015em;
          color: #446372;
          margin-bottom: 0.3125rem;
        `;
      default:
        return `
        `;
    }
  }};
`;

export const TabLabelList = styled.ul`
  display: flex;
  gap: 0.625rem;
  padding-left: 0;
  flex-wrap: wrap;
  margin: 0.3125rem 0;
  list-style-type: none;
  li {
    color: #fff;
    letter-spacing: 0.005em;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 120%;
    padding: 0.15625rem 0.75rem;
    background-color: #446372;
    border-radius: 50px;
  }
`;

export const TabHeading = ({ level, children, noTabCardMobile }) => {
  return (
    <Heading as={`h${level}`} level={level}>
      {children}
    </Heading>
  );
};

export const TabHeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media ${breakpoints.tablet} {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const IframeCard = styled.div`
  margin-bottom: 10px;
  padding: 15px;
  background: #fff; 
  border-radius: 20px; 
  box-shadow: 0px 10px 15px 0px rgba(132, 131, 131, 0.15);
`

export const CourseListIframe = styled.iframe`
    width: 100%; 
    height: 500px; 
    border: none; 
`


TabHeading.propTypes = {
  level: PropTypes.number,
  children: PropTypes.node.isRequired,
  noTabCardMobile: PropTypes.bool,
};

TabHeading.defaultProps = {
  level: 1,
  noTabCardMobile: false,
};
